import React from 'react';

export const AuthenticationMobileContext = React.createContext({
  showLogin: false,
  setShowLogin: () => {},
  showSignUp: false,
  setShowSignUp: () => {},
  onToggleLogin: () => {},
  closeLogin: () => {},
  closeSignUp: () => {},
  showModalSignUpSuccess: false,
  setShowModalSignUpSuccess: () => {},
  setSignUpResult: () => {},
  openForgotPassword: () => {},
  closeForgotPassword: () => {},
});

export const ForgotPasswordContext = React.createContext({
  setStep: () => {},
  setShowEnterOTPCode: () => {},
  setForgotPasswordSuccess: () => {},
});

export const useForgotPasswordContext = () =>
  React.useContext(ForgotPasswordContext);

export const useAuthenticationMobileContext = () =>
  React.useContext(AuthenticationMobileContext);
