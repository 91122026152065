import React from 'react';

export const ModalLoginContext = React.createContext({
  open: false,
  closeModal: () => {},
  showModalSignup: false,
  setShowModalSignup: () => {},
  setSignUpResult: {},
  setShowModalSuccess: () => {},
  setShowForgotPasswordModal: () => {},
  setOpenModalUpdateAcc: () => {},
});

export const SignInContext = React.createContext({
  setSocialData: () => {},
  socialData: {},
  setIsSignIn: () => {},
  setOpenModal: () => {},
  setErrorMessage: () => {},
});

export const useModalLoginContext = () => React.useContext(ModalLoginContext);

export const useSignInContext = () => React.useContext(SignInContext);
