import UpgradeSuccess from '@components/UpdatePremiumAccount/Components/UpgradeSuccess';
import { UpgradeAccountContext } from '@components/UpdatePremiumAccount/context';
import React, { useState } from 'react';

import DrawerWrapper from '@components/DrawerWrapper';
import dynamic from 'next/dynamic';
import EnterInfo from '@components/UpdatePremiumAccount/Components/EnterInfo';

const ModalWrapper = dynamic(() => import('@components/ModalWrapper'), {
  ssr: false,
});
type Props = {
  openModal?: boolean,
  setOpenModal?: Function,
};

export default function UpgradeAccountForMobile({
  openModal,
  setOpenModal,
}: Props) {
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  return (
    <UpgradeAccountContext.Provider
      value={{
        openModal,
        setOpenModal,
        openModalSuccess,
        setOpenModalSuccess,
      }}>
      {openModal && (
        <DrawerWrapper open={openModal} onClose={() => setOpenModal(false)}>
          <div className="w-full flex" style={{ height: '95vh' }}>
            <EnterInfo />
          </div>
        </DrawerWrapper>
      )}
      {openModalSuccess && (
        <ModalWrapper
          className="rounded-rd8px max-w-95%"
          open={openModalSuccess}
          onClose={() => setOpenModalSuccess(false)}>
          <UpgradeSuccess close={() => setOpenModalSuccess(false)} />
        </ModalWrapper>
      )}
    </UpgradeAccountContext.Provider>
  );
}
