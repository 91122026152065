import styles from '@styles/components/RadioButton.module.scss';

type Props = {
  isChecked?: boolean,
  disabled?: boolean,
  title?: string,
  titleClass?: string,
  value?: any,
  onPress?: Function,
  wrapperClass?: string,
};

export default function RadioButton({
  isChecked,
  title,
  titleClass,
  value,
  onPress = () => {},
  wrapperClass,
  disabled,
}: Props) {
  return (
    <button
      disabled={disabled}
      className={`flex flex-row items-center ${wrapperClass}`}
      onClick={() => onPress(value)}>
      <div className={`${styles.radioButton} ${isChecked && styles.checked}`}>
        <div
          className={`${styles.innerChecked} ${isChecked && 'bg-blue-main'}`}
        />
      </div>
      <div className={`text-13 font-text text-black ${titleClass}`}>
        {title}
      </div>
    </button>
  );
}
