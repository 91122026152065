import dynamic from 'next/dynamic';
import MyButton from '@components/Button';
import { onLogoutCallback } from '@utils/commonCallback';
import { useContext } from 'react';
import { AppContext } from '@utils/context/appContext';

const IconSuccess = dynamic(() => import('@public/svg/successful.svg'), {
  ssr: false,
});

type Props = {
  close?: Function,
  wrapperClass?: string,
};

export default function ChangePasswordSuccess({ close, wrapperClass }: Props) {
  const { setIsLogin } = useContext(AppContext);
  return (
    <div className={`flex flex-col flex-1 items-center ${wrapperClass}`}>
      <IconSuccess width={80} height={80} />
      <div className="heading2 mt-16px text-center">
        Đổi mật khẩu thành công
      </div>
      <div className="bodyText mt-4px text-center">
        Bạn đã đổi mật khẩu thành công
      </div>
      <MyButton
        label="Đóng"
        onPress={async () => {
          close();
          await onLogoutCallback();
          setIsLogin(false);
        }}
        wrapperClass="w-full mt-16px"
      />
    </div>
  );
}
