import { useMutation } from '@apollo/client';
import { UPLOAD_IMAGE } from '@schemas/mutations/image';
import { v4 as uuidV4 } from 'uuid';
import { useCallback, useState, useEffect } from 'react';
import useList from 'react-use/lib/useList';
import { useFormContext } from 'react-hook-form';
import { useRouter } from 'next/router';
export const useUploadImage = ({
  mediaUrls,
  setMediaUrls,
  setOpenModal,
  fieldName = 'mediaUrls',
  maxImage = 6,
  maxVideo = 3,
  // isEdit,
  isContinue = false,
  setIsContinue,
  isRenew,
}) => {
  const [doUpload, { loading }] = useMutation(UPLOAD_IMAGE);
  const [mediaFiles, { push, update, filter, set, clear }] = useList(mediaUrls);
  const [isSuccess, setIsSuccess] = useState(false);
  const { watch } = useFormContext();
  const totalFile = watch(fieldName);
  const totalImageFile = totalFile?.filter((e) =>
    e?.type?.includes('image'),
  )?.length;
  const totalVideoFile = totalFile?.filter(
    (e) => e?.type?.includes('video') || e?.type?.includes('youtube'),
  )?.length;
  const router = useRouter();
  const isEdit = router?.query?.isEdit === 'true';

  useEffect(() => {
    if (mediaFiles?.length > 0) {
      setIsSuccess(true);
    }
  }, [mediaFiles]);
  useEffect(() => {
    if ((isEdit && !isSuccess) || (isRenew && !isSuccess)) {
      set([...(mediaUrls || [])]);
    }
  }, [mediaUrls, isSuccess]);
  useEffect(() => {
    if (isSuccess) {
      setMediaUrls([...(mediaFiles || [])]);
    }
  }, [mediaFiles, isSuccess]);

  useEffect(() => {
    if (isContinue) {
      set([]);
      setIsContinue(false);
    }
  }, [isContinue, setIsContinue]);
  const uploadSingleFile = useCallback(async (toUploadFile) => {
    const temporaryId = uuidV4();
    try {
      await push({
        fileId: temporaryId,
        url: URL.createObjectURL(toUploadFile),
        name: toUploadFile.name,
        loading: true,
        progress: 0,
        type: toUploadFile?.type?.includes('video') ? 'video' : 'image',
      });
      const { data } = await doUpload({
        variables: {
          file: toUploadFile,
        },
        context: {
          fetchOptions: {
            onUploadProgress: (progress) => {
              update((e) => e.fileId === temporaryId, {
                fileId: temporaryId,
                url: URL.createObjectURL(toUploadFile),
                name: toUploadFile.name,
                loading: true,
                progress: progress.loaded / progress.total,
                type: toUploadFile?.type?.includes('video') ? 'video' : 'image',
              });
            },
          },
        },
      });
      await update((e) => e.fileId === temporaryId, {
        fileId: data.singleUpload.payload.fileId,
        name: toUploadFile.name,
        url: URL.createObjectURL(toUploadFile),
        type: toUploadFile.type.includes('video') ? 'video' : 'image',
      });
    } catch (e) {
      console.log(e);
    }
  }, []);
  let Promise = require('es6-promise').Promise;
  const doUploadImage = useCallback(
    async (files) => {
      const isMaxImageFile =
        files?.filter((e) => e?.type?.includes('image'))?.length +
          totalImageFile >
        maxImage;
      const isMaxVideoFile =
        files?.filter((e) => e?.type?.includes('video'))?.length +
          totalVideoFile >
        maxVideo;
      let count = 0;
      if (files?.length + totalFile?.length > 9) {
        setOpenModal(true);
      } else if (
        isMaxImageFile &&
        files?.filter((e) => e?.type?.includes('image'))?.length > 0
      ) {
        setOpenModal(true);
      } else if (
        isMaxVideoFile &&
        files?.filter((e) => e?.type?.includes('video'))?.length > 0
      ) {
        setOpenModal(true);
      } else {
        files.forEach(function (element) {
          if (
            (element?.type?.includes('image') &&
              element?.size / 1048576 > 10) ||
            (element?.type?.includes('video') && element?.size / 1048576 > 10)
          ) {
            count = count + 1;
          }
        });
        if (count > 0) {
          setOpenModal(true);
        } else {
          await Promise.all(files.map(uploadSingleFile));
        }
      }
    },
    [doUpload, totalFile, uploadSingleFile, totalImageFile, totalVideoFile],
  );
  const removeFile = useCallback(
    (tempId) => {
      filter((e) => e.fileId !== tempId);
    },
    [filter],
  );
  return { doUploadImage, loading, removeFile, push, clear };
};
