import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_LIST_PROVINCES } from '@schemas/queries/map';
import MyDropdownForPublish from '@components/pages/PublishRealEstate/components/MyDropdownForPublish';
type Props = {
  value?: string,
  setValue?: Function,
  buttonDropdownClassName?: string,
  placeholder?: string,
  onDelete?: Function,
  additionalItems?: Array,
  errorMsg?: string,
  title?: string,
  hideDeleteButton?: boolean,
  isBigArrow?: boolean,
  disabled?: boolean,
  configs?: Array,
  getObject?: boolean,
};

export function convertDataSelections(data) {
  return data?.map((item) => ({ key: item.id, value: item.name, ...item }));
}
export default React.forwardRef(CityDropdown);
function CityDropdown(props: Props, ref) {
  const {
    placeholder,
    value,
    setValue,
    buttonDropdownClassName,
    onDelete,
    additionalItems = [],
    errorMsg,
    title,
    hideDeleteButton = true,
    isBigArrow = false,
    disabled,
    configs,
    getObject = false,
  } = props;
  const { data, loading } = useQuery(GET_LIST_PROVINCES),
    rawData = [...(additionalItems || []), ...(data?.getListProvinces || [])];
  const dataSelections = convertDataSelections(rawData);
  return (
    <MyDropdownForPublish
      ref={ref}
      getObject={getObject}
      loading={loading}
      disabled={disabled}
      isBigArrow={isBigArrow}
      title={title}
      hideDeleteButton={hideDeleteButton}
      isShowSearchBox={true}
      value={value}
      setValue={setValue}
      configs={configs ? configs : dataSelections}
      placeholder={placeholder}
      buttonDropdownClassName={buttonDropdownClassName}
      errorMsg={errorMsg}
      onDelete={onDelete}
    />
  );
}
