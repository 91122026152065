import styles from '@styles/components/VerifyPhone.module.scss';
import MyButton from '@components/Button';

type Props = {
  close?: Function,
  errorMessage?: string,
};

export default function SendOTPError({ close, errorMessage }: Props) {
  return (
    <div className={styles.sendOTPErrorModal}>
      <img
        className="w-80px h-80px self-center"
        src="/svg/error.svg"
        alt="error"
      />
      <p
        className="text-24 font-textBold font-bold text-center"
        style={{ color: '#FF0000' }}>
        Bạn đã sử dụng OTP quá 3 lần
      </p>
      <p
        className="text-13 font-text mt-8px text-center"
        style={{ color: '#80888F' }}>
        {errorMessage}
      </p>
      <MyButton label="Đóng" wrapperClass="mt-24px" onPress={close} />
    </div>
  );
}
