import UploadImage from '@components/UpdatePremiumAccount/Components/UploadImage';
import { useFormContext, Controller } from 'react-hook-form';
import Label from '@components/label';
import ChangeAvatar from '@components/ChangeAvatar';
import { useCallback } from 'react';
import ErrorForFormHook from '@components/ErrorForFormHook';

export default function UpLoadImageInfo() {
  const { control, watch, setValue } = useFormContext();

  const isCompany = watch('isCompany');

  const updateAvatar = useCallback(async ({ fileId }) => {
    setValue('avatar', fileId);
  }, []);

  return (
    <div
      className="flex flex-col overflow-auto flex-1"
      style={{ padding: '12px 12px 24px' }}>
      {!isCompany && (
        <p className="heading4">
          Bạn hãy chụp CMND/ CCCD và Avatar thật để nhận các ưu đãi và bảo vệ
          tài khoản
        </p>
      )}
      {isCompany && (
        <p className="heading4">
          Bạn hãy chụp Giấy phép doanh nghiệp và Avatar thật để nhận các ưu đãi
          và bảo vệ tài khoản
        </p>
      )}
      {!isCompany && (
        <>
          <Controller
            control={control}
            render={({ field: { value, onChange }, formState: { errors } }) => {
              return (
                <div className="flex w-full flex-col mt-24px">
                  <Label
                    label="Hình ảnh CMND/ CCCD mặt trước"
                    wrapperClass="mb-4px"
                  />
                  <UploadImage
                    errors={errors}
                    description={
                      'Bấm vào nút “Chọn ảnh tải lên" để đăng tải hình ảnh CMND/ CCCD\nLưu ý: Mỗi hình ảnh không quá 1MB, tối đa 6 ảnh'
                    }
                    name="frontIdImages"
                    mediaUrls={value}
                    setMediaUrls={onChange}
                    maxFiles={6}
                  />
                </div>
              );
            }}
            name="frontIdImages"
          />
          <Controller
            control={control}
            render={({ field: { value, onChange }, formState: { errors } }) => {
              return (
                <div className="flex w-full flex-col mt-24px border-t border-gray-300 pt-16px">
                  <Label
                    label="Hình ảnh CMND/ CCCD mặt sau"
                    wrapperClass="mb-4px"
                  />
                  <UploadImage
                    errors={errors}
                    description={
                      'Bấm vào nút “Chọn ảnh tải lên" để đăng tải hình ảnh CMND/ CCCD\nLưu ý: Mỗi hình ảnh không quá 1MB, tối đa 6 ảnh'
                    }
                    name="backIdImages"
                    mediaUrls={value}
                    setMediaUrls={onChange}
                    maxFiles={6}
                  />
                </div>
              );
            }}
            name="backIdImages"
          />
        </>
      )}
      {isCompany && (
        <Controller
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => {
            return (
              <div className="flex w-full flex-col mt-24px">
                <Label
                  wrapperClass="mb-4px"
                  label="Hình ảnh và giấy phép kinh doanh của doanh nghiệp"
                  isRequire
                />
                <UploadImage
                  errors={errors}
                  description={
                    'Bấm vào nút “Chọn ảnh tải lên" để đăng tải hình ảnh giấy phép kinh doanh của doanh nghiệp\nLưu ý: Mỗi hình ảnh không quá 1MB, tối đa 6 ảnh'
                  }
                  name="companyLicense"
                  mediaUrls={value}
                  setMediaUrls={onChange}
                  maxFiles={6}
                />
              </div>
            );
          }}
          name="companyLicense"
        />
      )}
      <Controller
        control={control}
        render={({ field: { value }, formState: { errors } }) => {
          return (
            <div className="flex flex-col w-full border-t border-gray-300 mt-32px pt-24px">
              <div className="flex w-full flex-col lg:flex-row items-center lg:items-start">
                <Label label="Avatar" />
                <ChangeAvatar avatarId={value} setAvatar={updateAvatar} />
              </div>
              <p className="heading4 mt-16px text-center">
                Nên chọn avatar thật để nhận các ưu đãi và bảo vệ tài khoản
              </p>
              <ErrorForFormHook name="avatar" errors={value ? null : errors} />
            </div>
          );
        }}
        name="avatar"
      />
    </div>
  );
}
