import dynamic from 'next/dynamic';

const ModalWrapper = dynamic(() => import('@components/ModalWrapper'));
import MyButton from '@components/Button';
import React from 'react';

type Props = {
  open?: boolean,
  onClose?: Function,
};
export default function UploadErrorModal({ open, onClose }: Props) {
  return (
    <ModalWrapper
      hideClose
      className="bg-white w-600px h-350px max-w-95% rounded-rd9px p-6"
      open={open}
      onClose={onClose}>
      <div className="flex flex-col w-full h-full">
        <div
          style={{ color: '#EC6E00' }}
          className="w-full text-center text-xl sm:text-2xl font-bold">
          KHÔNG THỂ ĐĂNG ẢNH!
        </div>
        <div className="w-full flex justify-center">
          <img
            className="w-249px h-155px"
            alt="image"
            src="/images/uploadError.webp"
          />
        </div>
        <div className="w-full flex flex-col space-y-1.5 px-0 sm:px-16">
          <div className="w-full flex flex-row items-center space-x-2">
            <div className="w-5px h-5px rounded-full bg-blue-main" />
            <div
              className="text-sm sm:text-18 text-blue-main"
              dangerouslySetInnerHTML={{
                __html: `Tổng số lượng hình ảnh không vượt quá <b>6 ảnh</b>`,
              }}
            />
          </div>
          <div className="w-full flex flex-row items-center space-x-2">
            <div className="w-5px h-5px rounded-full bg-blue-main" />
            <div
              className="text-sm sm:text-18 text-blue-main"
              dangerouslySetInnerHTML={{
                __html: `Kích thước hình ảnh không vượt quá <b>1 MB</b>`,
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-1 items-end justify-center">
          <div className="w-148px" onClick={onClose}>
            <MyButton type="primary" label="Trở về" />
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}
