import React, { useState } from 'react';
import styles from '@styles/components/Input.module.scss';

export type InputProps = {
  label?: string,
  name?: string,
  inputClassName?: string,
  renderComponent?: Function,
  invalid?: boolean,
  errorMessage?: string,
  validationText?: string,
  inputOuterClassName?: string,
  maxLength?: number,
  onDelete?: Function,
  hideDelete?: boolean,
  inputInnerClassName?: string,
  disabled?: boolean,
  hideMaxLength?: boolean,
  showShadow?: boolean,
  AddressValidate?: boolean,
  inputStyles?: string,
  errorMessageStyle?: string,
};

export default React.forwardRef(MyInput);

function MyInput(props: InputProps, ref) {
  const {
    label,
    errorMessage,
    renderComponent,
    inputClassName,
    inputOuterClassName,
    invalid,
    maxLength,
    onDelete,
    hideDelete = false,
    inputInnerClassName,
    disabled,
    hideMaxLength = false,
    showShadow = true,
    AddressValidate,
    inputStyles,
    errorMessageStyle,
    ...inputProps
  } = props;
  const [focused, setOnFocus] = useState(false);
  return (
    <div
      className={`relative space-y-2 ${inputClassName} ${
        invalid ? 'errorInput' : ''
      }`}>
      {label && (
        <div className="flex-1">
          <div className={styles.label}>{label}</div>
        </div>
      )}
      <div
        style={{
          boxShadow:
            focused && showShadow
              ? '0px 0px 3px 3px rgba(67,142,255,0.26)'
              : undefined,
        }}
        className={`flex flex-row ${styles.inputContainer} ${inputStyles}  ${
          disabled ? 'bg-gray-100' : undefined
        } ${
          invalid || errorMessage?.length > 0
            ? styles.inputError
            : inputOuterClassName
        }`}>
        <input
          {...inputProps}
          onWheel={(event) => event.currentTarget.blur()}
          ref={ref}
          disabled={disabled}
          maxLength={maxLength}
          onFocus={(e) => {
            inputProps.onFocus && inputProps.onFocus(e);
            setOnFocus(true);
          }}
          onBlur={(e) => {
            inputProps.onBlur && inputProps.onBlur(e);
            setOnFocus(false);
          }}
          onKeyPress={(e) => {
            if (e.target.value?.length === maxLength) {
              e.preventDefault();
            }
            if (AddressValidate) {
              const charCode = e.charCode;
              if (
                (charCode < 97 || charCode > 122) &&
                (charCode < 65 || charCode > 90) &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 47 &&
                charCode !== 45
              ) {
                e.preventDefault();
              }
            }
          }}
          className={`${styles.innerInput} ${
            disabled ? 'bg-gray-100' : undefined
          } ${inputInnerClassName}`}
        />
        <div
          className={`flex flex-col items-end mr-3 ${
            maxLength && !hideMaxLength ? 'justify-between' : 'justify-center'
          }`}>
          {inputProps?.value && !hideDelete ? (
            <div
              onClick={!disabled ? onDelete : () => {}}
              className="w-20px h-20px cursor-pointer">
              <img
                className="w-full h-full ml-1"
                alt="close"
                src="/svg/ico-close.svg"
              />
            </div>
          ) : (
            <div className="w-20px h-20px cursor-pointer" />
          )}
          <div style={{ fontSize: 10 }} className="text-gray-400">
            {maxLength &&
              !hideMaxLength &&
              maxLength - inputProps?.value?.length}
          </div>
        </div>
      </div>
      {renderComponent && renderComponent({ focused })}
      {errorMessage && (
        <div
          className={` text-red-400 ${
            errorMessageStyle ? errorMessageStyle : `text-sm`
          }`}>
          {errorMessage}
        </div>
      )}
    </div>
  );
}
