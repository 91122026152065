import { useCallback, useRef, useState } from 'react';

export default function useOpenModalLogin() {
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);
  const gotoLogin = useCallback((flag = true) => {
    setShowModal(true);
    modalRef?.current?.setIsSignIn(flag);
  }, []);
  return { gotoLogin, showModal, setShowModal, modalRef };
}
