import PageTitle2 from '@components/pageTitle2';
import { hotline, maxLength } from '@utils/configs/Common/CommonConfigs';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { changePasswordSchema } from '@utils/validation/auth';
import { getErrorsFormHook } from '@utils/helper';
import { useMutation } from '@apollo/client';
import { CHANGE_PASSWORD } from '@schemas/mutations/auth';
import { useCallback, useState } from 'react';
import Label from '@components/label';
import SecureInput from '@components/inputV2/SecureInput';
import MyButton from '@components/Button';
import dynamic from 'next/dynamic';
import ChangePasswordSuccess from '@components/modals/changePassword/changePasswordSuccess';

const ModalWrapper = dynamic(() => import('@components/ModalWrapper'), {
  ssr: false,
});

type Props = {
  close?: Function,
};
export default function ChangePassword({ close }: Props) {
  const [success, setSuccess] = useState(false);
  const form = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    resolver: async (data) => {
      const { error, value: values } = changePasswordSchema.validate(
        { ...data },
        {
          abortEarly: false,
        },
      );
      return getErrorsFormHook({ error, values });
    },
  });

  const { control, handleSubmit, setError } = form;

  const [actionChangePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
    onError: (e) =>
      setError('currentPassword', { type: 'manual', message: e.message }),
    onCompleted: async () => {
      setSuccess(true);
    },
  });

  const onChangePassword = useCallback(
    async (data) => {
      try {
        await actionChangePassword({
          variables: {
            oldPassword: data.currentPassword,
            newPassword: data.newPassword,
            confirmNewPassword: data.confirmPassword,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [actionChangePassword],
  );

  return (
    <div
      className="w-full bg-white flex flex-col relative"
      style={{ height: '100vh' }}>
      <PageTitle2 title="Đổi mật khẩu" onRowClick={close} />
      <div className="flex-1 flex flex-col px-12px mt-24px pb-24px">
        <p className="bodyText">
          Bạn sẽ sử dụng mật khẩu mới này để truy cập lại vào tài khoản của
          mình. Nhập mật khẩu có tối thiểu 8 và tối đa 32 ký tự.
        </p>
        <FormProvider {...form}>
          <Controller
            control={control}
            render={({
              field: { value, onChange },
              fieldState: { invalid },
              formState: { errors },
            }) => (
              <div className="flex flex-col mt-16px">
                <Label
                  label="Nhập mật khẩu cũ"
                  isRequire
                  wrapperClass="mb-8px"
                />
                <SecureInput
                  inputClass="h-40px"
                  invalid={invalid}
                  errors={errors}
                  name="currentPassword"
                  value={value}
                  onChange={(e) =>
                    onChange(e.target.value.substr(0, maxLength.password))
                  }
                  inputOuterClassName="flex-1"
                  inputClassName="flex flex-row items-center"
                  placeholder="Nhập lại mật khẩu cũ"
                  isRequire
                />
              </div>
            )}
            name="currentPassword"
          />
          <Controller
            control={control}
            render={({
              field: { value, onChange },
              fieldState: { invalid },
              formState: { errors },
            }) => (
              <div className="flex flex-col mt-16px">
                <Label
                  label="Nhập mật khẩu mới"
                  isRequire
                  wrapperClass="mb-8px"
                />
                <SecureInput
                  inputClass="h-40px"
                  invalid={invalid}
                  errors={errors}
                  name="newPassword"
                  value={value}
                  onChange={(e) =>
                    onChange(e.target.value.substr(0, maxLength.password))
                  }
                  inputOuterClassName="flex-1"
                  inputClassName="flex flex-row items-center"
                  placeholder="Nhập mật khẩu mới"
                  isRequire
                />
              </div>
            )}
            name="newPassword"
          />
          <Controller
            control={control}
            render={({
              field: { value, onChange },
              fieldState: { invalid },
              formState: { errors },
            }) => (
              <div className="flex flex-col mt-16px">
                <Label
                  label="Nhập lại mật khẩu mới"
                  isRequire
                  wrapperClass="mb-8px"
                />
                <SecureInput
                  inputClass="h-40px"
                  invalid={invalid}
                  errors={errors}
                  name="confirmPassword"
                  value={value}
                  onChange={(e) =>
                    onChange(e.target.value.substr(0, maxLength.password))
                  }
                  inputOuterClassName="flex-1"
                  inputClassName="flex flex-row items-center"
                  placeholder="Nhập lại mật khẩu"
                  isRequire
                />
              </div>
            )}
            name="confirmPassword"
          />
        </FormProvider>
        <MyButton
          label="Xác nhận"
          wrapperClass="w-full mt-16px"
          onPress={handleSubmit(onChangePassword)}
          disabled={loading}
        />
      </div>
      <div className="bg-white flex flex-col w-full p-12px border-t border-gray-300 sticky bottom-0">
        <p className="text-16 font-textBold font-bold text-blue-main">
          Quên email hoặc số điện thoại
        </p>
        <p className="bodyText mt-8px">
          Gọi về số hỗ trợ{' '}
          <a
            href={`tel:+${hotline}`}
            className="font-bold font-textBold text-blue-main">
            {hotline}
          </a>{' '}
          để xin cấp lại mật khẩu
        </p>
      </div>
      {success && (
        <ModalWrapper
          open={success}
          onClose={() => setSuccess(false)}
          className="rounded-rd8px">
          <ChangePasswordSuccess
            wrapperClass="p-16px"
            close={() => {
              setSuccess(false);
              close();
            }}
          />
        </ModalWrapper>
      )}
    </div>
  );
}
