import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation SignIn(
    $emailOrPhone: String!
    $password: String!
    $savedLogin: Boolean
  ) @api(name: users) {
    signIn(
      emailOrPhone: $emailOrPhone
      password: $password
      savedLogin: $savedLogin
    ) {
      success
      msg
      payload {
        accessToken
      }
    }
  }
`;
export const SEND_VERIFY_CODE = gql`
  mutation SendVerifyCode($phone: String!, $type: SendVerificationCodeType!)
  @api(name: users) {
    sendVerifyCode(phone: $phone, type: $type) {
      success
      msg
    }
  }
`;
export const SEND_CODE = gql`
  mutation SendCode(
    $phone: String!
    $type: SendVerificationCodeType!
    $identifier: String!
  ) @api(name: users) {
    sendCode(phone: $phone, type: $type, identifier: $identifier) {
      payload
      success
      msg
    }
  }
`;

export const VERIFY_PHONE = gql`
  mutation VerifyPhone(
    $phone: String!
    $type: SendVerificationCodeType!
    $otp: String!
  ) @api(name: users) {
    verifyPhone(phone: $phone, type: $type, OTP: $otp) {
      payload
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOTP(
    $phone: String!
    $type: SendVerificationCodeType!
    $otp: String!
    $identifier: String!
  ) @api(name: users) {
    verifyOTP(phone: $phone, type: $type, OTP: $otp, identifier: $identifier) {
      payload
      success
      msg
    }
  }
`;

export const SIGNUP = gql`
  mutation SignUp(
    $password: String!
    $userType: String!
    $signUpType: String!
    $fullName: String
    $phone: String!
    $phoneVerifyToken: String!
    $companyName: String
    $companyFields: [String]
    $taxId: String
    $developer: ObjectID
  ) @api(name: users) {
    signUp(
      inputData: {
        password: $password
        type: $userType
        signupType: $signUpType
        fullName: $fullName
        phone: $phone
        phoneVerifyToken: $phoneVerifyToken
        companyName: $companyName
        companyFields: $companyFields
        taxId: $taxId
        developer: $developer
      }
    ) {
      success
      msg
      payload {
        accessToken
        memberId
        developer
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation SignOut @api(name: users) {
    logOut {
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($authString: String!, $identifier: String!)
  @api(name: users) {
    forgotPassword(authString: $authString, identifier: $identifier) {
      message
    }
  }
`;

export const VERIFY_FORGOT_PASSWORD = gql`
  mutation VerifyForgotPassword($authString: String!, $otp: String!)
  @api(name: users) {
    verifyForgotPassword(authString: $authString, OTP: $otp) {
      msg
      success
      payload
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $authString: String!
    $newPassword: String!
    $confirmNewPassword: String!
    $secretCode: String!
  ) @api(name: users) {
    resetPassword(
      authString: $authString
      newPassword: $newPassword
      confirmNewPassword: $confirmNewPassword
      secretCode: $secretCode
    ) {
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $oldPassword: String!
    $newPassword: String!
    $confirmNewPassword: String!
  ) @api(name: users) {
    changePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      confirmNewPassword: $confirmNewPassword
    ) {
      success
      msg
      payload
    }
  }
`;

export const SOCIAL_SIGN_IN = gql`
  mutation socialSignIn(
    $type: SocialTypeEnumType!
    $idToken: String!
    $phone: String
    $nonce: String
  ) @api(name: users) {
    socialSignIn(type: $type, idToken: $idToken, phone: $phone, nonce: $nonce) {
      success
      msg
      payload {
        ... on SocialAccountType {
          id
          email
          accountId
          lastName
          fullName
        }
        ... on AccessTokenType {
          accessToken
        }
      }
    }
  }
`;

export const GOOGLE_LOGIN = gql`
  mutation googleLogin($idToken: String!) @api(name: users) {
    googleLogin(idToken: $idToken) {
      ... on AccessTokenType {
        accessToken
        memberId
        system
        developer
      }
      ... on SocialResponseDataType {
        fullName
      }
    }
  }
`;

export const FACEBOOK_LOGIN = gql`
  mutation facebookLogin($idToken: String!) @api(name: users) {
    facebookLogin(idToken: $idToken) {
      ... on AccessTokenType {
        accessToken
        memberId
        system
        developer
      }
      ... on SocialResponseDataType {
        fullName
      }
    }
  }
`;

export const APPLE_LOGIN = gql`
  mutation appleLogin($idToken: String!) @api(name: users) {
    appleLogin(idToken: $idToken) {
      ... on AccessTokenType {
        accessToken
        memberId
        system
        developer
      }
      ... on SocialResponseDataType {
        fullName
      }
    }
  }
`;

export const SOCIAL_SIGNUP = gql`
  mutation socialSignUp(
    $type: SocialTypeEnumType!
    $idToken: String!
    $userType: String!
    $signUpType: String!
    $phone: String!
    $phoneVerifyToken: String!
    $password: String!
    $fullName: String
    $companyName: String
    $taxId: String
    $companyFields: [String]
    $developer: ObjectID
  ) @api(name: users) {
    socialSignUp(
      type: $type
      idToken: $idToken
      inputData: {
        password: $password
        type: $userType
        signupType: $signUpType
        fullName: $fullName
        phone: $phone
        phoneVerifyToken: $phoneVerifyToken
        companyName: $companyName
        companyFields: $companyFields
        taxId: $taxId
        developer: $developer
      }
    ) {
      payload {
        accessToken
        memberId
        developer
      }
    }
  }
`;

export const IS_VALID_PASSWORD = gql`
  mutation isValidPassword($password: String!) @api(name: users) {
    isValidPassword(password: $password)
  }
`;

export const CHANGE_PRIMARY_PHONE = gql`
  mutation changePrimaryPhone(
    $oldPhone: String!
    $newPhone: String!
    $phoneVerifyCode: String!
  ) @api(name: users) {
    changePrimaryPhone(
      oldPhone: $oldPhone
      newPhone: $newPhone
      phoneVerifyCode: $phoneVerifyCode
    ) {
      msg
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation updateUser(
    $fullName: String
    $phones: [String]
    $avatar: ObjectID
    $companyName: String
    $isShareInfo: Boolean
  ) @api(name: users) {
    updateUser(
      avatar: $avatar
      fullName: $fullName
      phones: $phones
      isShareInfo: $isShareInfo
      companyName: $companyName
    ) {
      message
    }
  }
`;

export const UPGRADE_PREMIUM_ACCOUNT = gql`
  mutation UpgradePremiumAccount(
    $fullName: String
    $province: ObjectID
    $birthDate: Date
    $establishedDate: Date
    $gender: GenderEnumType
    $idNumber: String
    $company: String
    $companyName: String
    $frontIdImages: [ObjectID]
    $backIdImages: [ObjectID]
    $companyLicense: [ObjectID]
    $avatar: ObjectID
    $email: String
    $description: String
  ) @api(name: users) {
    upgradePremiumAccount(
      fullName: $fullName
      province: $province
      birthDate: $birthDate
      establishedDate: $establishedDate
      gender: $gender
      idNumber: $idNumber
      company: $company
      companyName: $companyName
      frontIdImages: $frontIdImages
      backIdImages: $backIdImages
      companyLicense: $companyLicense
      avatar: $avatar
      email: $email
      description: $description
    ) {
      msg
    }
  }
`;
