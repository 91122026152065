import { Slider } from '@material-ui/core';
import Cropper from 'react-easy-crop';
import { useMutation } from '@apollo/client';
import { UPLOAD_IMAGE } from '@schemas/mutations/image';
import { useCallback, useEffect, useState } from 'react';
import { cropImage } from '@utils/helper';
import MyButton from '@components/Button';
import styles from '@styles/components/CropAvatar.module.scss';

type Props = {
  close?: Function,
  configs: {
    onChangeImage?: Function,
    previewUrl?: string,
    file?: string,
  },
};

export default function CropAvatar(props: Props) {
  const {
    close,
    configs: { onChangeImage, previewUrl, file },
  } = props;
  const [upload, { loading }] = useMutation(UPLOAD_IMAGE);

  const uploadImage = useCallback(
    async (cropAreaPixels) => {
      try {
        const croppedImageBlob = await cropImage(
          URL.createObjectURL(file),
          { x: cropAreaPixels.x, y: cropAreaPixels.y },
          cropAreaPixels.width,
          cropAreaPixels.height,
        );
        croppedImageBlob.name = file.name;
        const { data } = await upload({
          variables: { file: croppedImageBlob },
        });
        onChangeImage({
          fileId: data.singleUpload.payload.fileId,
          originalUrl: data.singleUpload.payload.originalUrl,
          loading,
        });
        close();
      } catch (e) {
        console.log(e);
        onChangeImage({ fileId: null, loading: false, originalUrl: null });
      }
    },
    [close, loading, onChangeImage, upload, file],
  );

  const [crop, setCrop] = useState({ x: 0, y: 0 }),
    [firstZoom, setFirsZoom] = useState(false),
    [minZoom, setMinZoom] = useState(1),
    [maxZoom, setMaxZoom] = useState(3),
    [zoom, setZoom] = useState(1),
    disabledZoomOut = zoom <= minZoom,
    disabledZoomIn = zoom >= maxZoom;

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixel) => {
    setCroppedAreaPixels(croppedAreaPixel);
  }, []);

  useEffect(() => {
    setMaxZoom(minZoom + 3);
  }, [minZoom]);

  const onMediaLoaded = useCallback(
    ({ width, height }) => {
      let nextMinZoom;
      if (width >= height) {
        nextMinZoom = 300 / height;
      } else {
        nextMinZoom = 300 / width;
      }
      setMinZoom(nextMinZoom);
      if (!firstZoom) {
        setZoom(nextMinZoom);
      }
      setFirsZoom(true);
    },
    [firstZoom],
  );

  return (
    <div className={styles.container}>
      <p className="text-18 text-center font-bold font-textBold text-blue-main mb-16px">
        Thay đổi avatar
      </p>
      <div
        className="w-full h-300px relative border border-blue-main"
        style={{
          opacity: 0.73,
          backgroundColor: '#222222',
        }}>
        <Cropper
          cropSize={{ width: 300, height: 300 }}
          showGrid={false}
          onCropChange={setCrop}
          crop={crop}
          image={previewUrl}
          onZoomChange={setZoom}
          zoom={zoom}
          minZoom={minZoom}
          maxZoom={maxZoom}
          onCropComplete={onCropComplete}
          onMediaLoaded={onMediaLoaded}
        />
      </div>
      <div className="w-full flex flex-row justify-center my-16px">
        <div className="w-300px flex flex-row">
          <button
            disabled={disabledZoomOut}
            className="text-18 font-bold font-textBold mr-12px"
            onClick={() => setZoom((cr) => cr - 0.1)}>
            -
          </button>
          <Slider
            value={zoom}
            min={minZoom}
            max={maxZoom}
            step={0.1}
            aria-labelledby={'Zoom'}
            onChange={(e, zoomImage) => setZoom(zoomImage)}
          />
          <button
            disabled={disabledZoomIn}
            className="text-18 font-bold font-textBold ml-12px"
            onClick={() => setZoom((cr) => cr + 0.1)}>
            +
          </button>
        </div>
      </div>
      <div className="flex flex-row w-full justify-end">
        <div className="w-full lg:w-80px mr-8px">
          <MyButton
            onPress={close}
            disabled={loading}
            label="Trở về"
            type="secondary"
          />
        </div>
        <div className="w-full lg:w-120px">
          <MyButton
            label="Cập nhật"
            onPress={() => uploadImage(croppedAreaPixels)}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
}
