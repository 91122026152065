import React, { useMemo, useState } from 'react';
import ArrowDown from '@public/svg/arrowV2.svg';
import useLazyModal from '@hooks/common/useLazyModal';
import isEmpty from 'lodash/isEmpty';
import DrawerWrapper from '@components/DrawerWrapper';
import Dropdown from '@components/Dropdown';
type Props = {
  DropdownComponent?: React.Component,
  customValue?: Function,
  value?: any,
  setValue?: Function,
  hideDeleteButton?: boolean,
  onDelete?: Function,
  disabled?: boolean,
  placeholder?: string,
  configs?: Array,
  isShowSearchBox?: boolean,
  contextProps?: any,
  wrapperStyleDrawer?: string,
  titleMobile?: string,
  hideHeader?: boolean,
  errorMsg?: string,
  wrapperContent?: string,
};

type ContentProps = {
  customValue?: Function,
  renderValue?: string,
  value?: string,
  hideDeleteButton?: boolean,
  onDelete?: Function,
  disabled?: boolean,
  setDisableDropdown?: Function,
  toggle?: boolean,
  wrapperContent?: string,
};
function Content({
  customValue,
  renderValue,
  value,
  hideDeleteButton,
  onDelete,
  disabled,
  setDisableDropdown,
  toggle,
  wrapperContent,
}: ContentProps) {
  return (
    <div
      className={`w-full flex flex-row items-center justify-between px-2 ${wrapperContent}`}>
      {customValue && customValue()}
      {!customValue && (
        <div
          className={`flex-1 px-2 text-left text-sm truncate ${
            !value || isEmpty(value) ? 'text-gray-400' : 'text-blue-main'
          }`}>
          {renderValue}
        </div>
      )}
      <div className="flex flex-row items-center space-x-2  pr-1">
        {value && !hideDeleteButton && (
          <div
            onClick={() => {
              onDelete && !disabled && onDelete();
              setDisableDropdown(true);
              setTimeout(() => {
                setDisableDropdown(false);
              }, 100);
            }}
            className="w-5 h-5 cursor-pointer">
            <img
              className="w-full h-full ml-1"
              alt="close"
              src="/svg/ico-close.svg"
            />
          </div>
        )}
        <ArrowDown
          fill="#888"
          className={`${
            toggle ? 'transform rotate-180 duration-300' : 'duration-300'
          }`}
        />
      </div>
    </div>
  );
}

export default function DropdownForSearchList({
  DropdownComponent,
  customValue,
  value,
  setValue,
  hideDeleteButton,
  onDelete,
  disabled,
  placeholder,
  configs,
  isShowSearchBox,
  contextProps,
  wrapperStyleDrawer,
  titleMobile,
  hideHeader,
  errorMsg,
  wrapperContent,
}: Props) {
  const [toggle, onToggle] = useState(false);
  const [toggleDrawer, onToggleDrawer] = useState(false);
  const { renderModal, onHover } = useLazyModal();
  const [disableDropdown, setDisableDropdown] = useState(false);
  const renderValue = useMemo(() => {
    if (!value || isEmpty(value)) {
      return placeholder;
    } else if (Array.isArray(value)) {
      if (value?.length === configs?.length) {
        return 'Tất cả';
      } else {
        return value?.map((item, index) => {
          return (
            configs?.find((e) => e.key === item)?.value +
            `${index === value?.length - 1 || value?.length === 1 ? '.' : ' ,'}`
          );
        });
      }
    } else {
      return `${configs?.find((e) => e.key === value)?.value}`;
    }
  }, [value, configs, placeholder]);
  const isMobile = process.browser && window.innerWidth <= 600;
  return (
    <div className="w-full space-y-1">
      {!isMobile ? (
        <Dropdown
          disabled={disableDropdown || disabled}
          show={toggle}
          onToggle={() => onToggle(!toggle)}
          DropdownComponent={DropdownComponent}
          dropdownContext={{
            value,
            setValue,
            configs,
            isShowSearchBox,
            ...contextProps,
          }}
          style={`flex items-center justify-center border h-38px rounded-rd9px bg-white ${
            errorMsg?.length ? 'border-red-400' : 'border-blue-main'
          } ${disabled ? 'bg-gray-100' : undefined}`}>
          <Content
            wrapperContent={wrapperContent}
            renderValue={renderValue}
            value={value}
            onDelete={onDelete}
            customValue={customValue}
            disabled={disabled}
            hideDeleteButton={hideDeleteButton}
            setDisableDropdown={setDisableDropdown}
            toggle={toggle}
          />
        </Dropdown>
      ) : (
        <div
          onMouseEnter={onHover}
          onClick={() => onToggleDrawer(!toggleDrawer)}
          className={`flex items-center justify-center border h-38px rounded-rd9px bg-white ${
            errorMsg?.length ? 'border-red-400' : 'border-blue-main'
          } ${disabled ? 'bg-gray-100 pointer-events-none' : undefined}`}>
          <Content
            wrapperContent={wrapperContent}
            renderValue={renderValue}
            value={value}
            onDelete={onDelete}
            customValue={customValue}
            disabled={disabled}
            hideDeleteButton={hideDeleteButton}
            setDisableDropdown={setDisableDropdown}
            toggle={toggle}
          />
        </div>
      )}
      {(renderModal || toggleDrawer) && !disableDropdown && (
        <DrawerWrapper
          open={toggleDrawer}
          onClose={() => onToggleDrawer(false)}
          container={process.browser && document.getElementById('modal-root')}
          className="w-full h-full">
          <div className={`w-full bg-white ${wrapperStyleDrawer}`}>
            {!hideHeader && (
              <div
                className="w-full flex flex-col border-b pr-3 py-1 bg-white sticky top-0 h-50px"
                style={{
                  borderColor: '#e2e2e2',
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                }}>
                <div className="flex justify-center items-center">
                  <div className="w-32px h-4px rounded-full bg-gray-500" />
                </div>
                <div className="flex flex-1 items-center justify-center">
                  <button
                    onClick={() => onToggleDrawer(false)}
                    className="w-48px ">
                    <img alt="down" src="/svg/rectangleDownIcon.svg" />
                  </button>
                  <div className="flex flex-1 justify-center items-center px-2 text-base font-bold">
                    {titleMobile}
                  </div>
                  <div className="w-40px" />
                </div>
              </div>
            )}
            <div className="w-full">
              <DropdownComponent
                context={{
                  value,
                  setValue,
                  configs,
                  isShowSearchBox,
                  ...contextProps,
                }}
                close={() => onToggleDrawer(false)}
              />
            </div>
          </div>
        </DrawerWrapper>
      )}
      {errorMsg && <div className="text-sm text-red-400">{errorMsg}</div>}
    </div>
  );
}
