import React from 'react';

export const UpgradeAccountContext = React.createContext({
  openModal: false,
  setOpenModal: () => {},
  openModalSuccess: false,
  setOpenModalSuccess: () => {},
});

export const useUpgradeAccountContext = () =>
  React.useContext(UpgradeAccountContext);
