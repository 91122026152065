export const companyFieldEnumType = [
  {
    key: 'townhouse',
    value: 'Công Ty BĐS Nhà phố',
  },
  {
    key: 'project',
    value: 'Công Ty bán Dự án',
  },
  {
    key: 'other',
    value: 'Công Ty khác',
  },
];

export const filedGetUser = [
  'type',
  'fullName',
  'avatar { ...FileAllFields }',
  'province { id name }',
  'phones',
  'gender',
  'birthDate',
  'companyName',
  'isPremiumAccount',
  'developMember { id fullName }',
  'email',
  'idNumber',
  'memberId',
  'frontIdImages',
  'backIdImages',
  'attachments',
  'companyFields',
  'description',
  'establishedDate',
  'taxId',
  'company',
  'statusProfile',
  'companyLicense',
  'statusProfileCompany',
  'mediaOfCompany',
];
