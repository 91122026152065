import React, { useMemo, useState } from 'react';
import MyInput from '@components/Input';
import useDebounce from 'react-use/lib/useDebounce';
import Fuse from 'fuse.js';
import Radio from '@components/Radio';
type SelectComponentProps = {
  configs?: Array,
  value?: string,
  setValue?: Function,
  isShowSearchBox?: boolean,
  close?: Function,
  title?: string,
  wrapperStyle?: string,
  getObject?: boolean,
  loading?: boolean,
};

type SelectItemProps = {
  onClick?: Function,
  value?: string,
  isChecked?: boolean,
};

function SelectItem({ onClick, value, isChecked }: SelectItemProps) {
  return (
    <div onClick={onClick} className="flex-1 py-2">
      <Radio title={value} isChecked={isChecked} />
    </div>
  );
}

export default function DrawerSelection(props: SelectComponentProps) {
  const {
    configs = [],
    value,
    setValue,
    isShowSearchBox,
    close,
    title,
    wrapperStyle,
    getObject,
    loading,
  } = props;
  const [search, setSearch] = useState('');
  const [debounce, setDebounce] = useState('');
  useDebounce(
    () => {
      setDebounce(search);
    },
    300,
    [search],
  );

  const options = {
      includeScore: true,
      keys: ['value'],
      threshold: 0.5,
      includeMatches: true,
      ignoreLocation: true,
      distance: 600,
    },
    fuse = new Fuse(configs, options),
    result = fuse?.search(debounce);

  const finalSelections = useMemo(() => {
    return debounce !== '' ? result?.map((e) => e.item) : [...(configs || [])];
  }, [debounce, result, configs]);
  return (
    <div className={`w-full bg-white ${wrapperStyle}`}>
      <div
        className="sticky top-0 w-full flex flex-col border-b pr-3 py-1 bg-white h-50px"
        style={{
          borderColor: '#e2e2e2',
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          zIndex: 1100,
        }}>
        <div className="flex justify-center items-center">
          <div className="w-32px h-4px rounded-full bg-gray-500" />
        </div>
        <div className="flex flex-1 items-center justify-center">
          <button onClick={close} className="w-48px ">
            <img alt="down" src="/svg/rectangleDownIcon.svg" />
          </button>
          <div className="flex flex-1 justify-center items-center px-2 text-base font-bold">
            {title}
          </div>
          <div className="w-40px" />
        </div>
      </div>
      <div className="w-full">
        {isShowSearchBox && (
          <div
            style={{ zIndex: 1100 }}
            className="flex-1 bg-white p-2 border-b border-gray-200">
            <MyInput
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Nhập từ khóa để tìm kiếm"
              onDelete={() => setSearch('')}
            />
          </div>
        )}
        <div
          style={{ height: 300 }}
          className="w-full px-6 overflow-scroll space-y-2 py-4">
          {!loading &&
            finalSelections?.map((item, index) => {
              const isActive = item.key === value;
              return (
                <SelectItem
                  isChecked={isActive}
                  key={index}
                  onClick={() => {
                    if (getObject) {
                      setValue(item);
                    } else {
                      setValue(item.key);
                    }
                    close();
                  }}
                  value={item.value}
                />
              );
            })}
          {loading && (
            <div className="flex flex-1 flex-row items-center justify-center space-x-1 py-4">
              <div className="text-sm text-blue-main">Đang tải</div>
              <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-main" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
