import { gql } from 'graphql-tag';

export const GET_LIST_PROVINCES = gql`
  query @api(name: map) {
    getListProvinces {
      id
      name
    }
  }
`;

export const GET_LIST_DISTRICT_OF_PROVINCE = gql`
  query ($provinceId: ObjectID!, $limit: Int, $page: Int, $searchString: String)
  @api(name: map) {
    getListDistrictsOfProvince(
      provinceId: $provinceId
      limit: $limit
      page: $page
      name: $searchString
    ) {
      page
      hasNextPage
      districts {
        id
        name
      }
      total
    }
  }
`;

export const GET_LIST_STREETS_OF_ONE_WARD = gql`
  query ($wardId: ObjectID!, $name: String, $limit: Int, $page: Int)
  @api(name: map) {
    getListStreetsOfOneWard(
      wardId: $wardId
      limit: $limit
      page: $page
      name: $name
    ) {
      page
      hasNextPage
      streets {
        id
        name
        location {
          coordinates
        }
      }
      total
    }
  }
`;

export const GET_LIST_WARD_OF_DISTRICT = gql`
  query (
    $districtId: ObjectID!
    $streetId: ObjectID
    $limit: Int
    $page: Int
    $searchString: String
  ) @api(name: map) {
    getListWardsOfDistrict(
      districtId: $districtId
      streetId: $streetId
      limit: $limit
      page: $page
      name: $searchString
    ) {
      page
      hasNextPage
      wards {
        id
        name
      }
      total
    }
  }
`;

export const GET_LIST_STREET_OF_DISTRICT = gql`
  query ($districtId: ObjectID!, $limit: Int, $page: Int, $searchString: String)
  @api(name: map) {
    getListStreetsOfDistrict(
      districtId: $districtId
      limit: $limit
      page: $page
      name: $searchString
    ) {
      page
      hasNextPage
      streets {
        id
        name
        location {
          coordinates
        }
      }
      total
    }
  }
`;

export const GET_LIST_STREET_OF_WARDS = gql`
  query ($wardIds: [ObjectID]!, $limit: Int, $page: Int, $searchString: String)
  @api(name: map) {
    getListStreetsOfWard(
      wardIds: $wardIds
      limit: $limit
      page: $page
      name: $searchString
    ) {
      page
      hasNextPage
      streets {
        id
        name
      }
      total
    }
  }
`;

export const AUTO_SUGGEST_PLACES = gql`
  query ($searchString: String!) @api(name: map) {
    autoSuggestPlaces(searchString: $searchString) {
      id
      placeId
      location {
        type
        coordinates
      }
      mainText
      fullText
      placeDetails
    }
  }
`;

export const PLACE_DETAILS = gql`
  query ($placeId: String!) @api(name: map) {
    placeDetails(placeId: $placeId) {
      id
      placeId
      mainText
      fullText
      location {
        type
        coordinates
      }
      placeDetails
    }
  }
`;

export const GET_PROVINCE_DETAILS = gql`
  query ($provinceId: String!) @api(name: map) {
    getProvinceDetails(provinceId: $provinceId) {
      id
      name
    }
  }
`;

export const GET_DISTRICT_DETAILS = gql`
  query ($districtId: String!) @api(name: map) {
    getDistrictDetails(districtId: $districtId) {
      id
      name
    }
  }
`;
export const GET_WARD_DETAILS = gql`
  query ($wardId: String!) @api(name: map) {
    getWardDetails(wardId: $wardId) {
      id
      name
    }
  }
`;

export const GET_STREET_DETAILS = gql`
  query ($streetId: String!) @api(name: map) {
    getStreetDetails(streetId: $streetId) {
      id
      name
    }
  }
`;

export const GET_NEAR_BY_LANDS_BY_LOCATION = gql`
  query getNearByLandsByLocation(
    $long: Float!
    $lat: Float!
    $radius: Float!
    $limit: Int
  ) @api(name: map) {
    getNearByLandsByLocation(
      long: $long
      lat: $lat
      radius: $radius
      limit: $limit
    ) {
      lands {
        id
        type
        plans {
          planningId
          color
          type
          polygon {
            type
            coordinates
          }
        }
        polygon {
          type
          coordinates
        }
      }
      plans {
        color
        landUse
        area
        polygon {
          type
          coordinates
        }
      }
      zones {
        polygon {
          type
          coordinates
        }
      }
    }
  }
`;

export const GET_LAND_DETAILS_BY_COORDINATE = gql`
  query getLandDetailsByCoordinate(
    $landId: ObjectID
    $lat: Float
    $long: Float
    $district: ObjectID
    $ward: ObjectID
    $parcelId: String
    $mapId: String
  ) @api(name: map) {
    getLandDetailsByCoordinate(
      landId: $landId
      lat: $lat
      long: $long
      district: $district
      ward: $ward
      parcelId: $parcelId
      mapId: $mapId
    ) {
      id
      type
      fullAddress
      mapId
      parcelId
      totalArea
      plans {
        type
        color
        area
        landUse
        planningInfo {
          functionalLot
          landUse
          area
          population
          height
          buildingDensity
          floorAreaRatio
        }
      }
      location {
        type
        coordinates
      }
      streetRightWayOf {
        streetId
        width
        direction
      }
      zonePlanningId {
        zoningPlan
        decisionId
        approvedBy
        dateOfApproval
      }
    }
  }
`;
export const GET_MAP_CONFIGS = gql`
  query @api(name: map) {
    getMapConfigurations {
      valuationYears {
        month
        year
        value
      }
    }
  }
`;

export const GET_SUGGESTIONS = gql`
  query getSuggestions($search: String!, $typeOfDemand: TypeOfDemand!)
  @api(name: map) {
    getSuggestions(search: $search, typeOfDemand: $typeOfDemand) {
      fullText
      district
      province
      ward
      street
      realEstateType
      typeOfDemand
    }
  }
`;
