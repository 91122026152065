import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useUploadImage } from '@utils/hooks/useUploadImage';

import styles from '@styles/components/UpdatePremiumAccount/UpdatePremiumAccount.module.scss';
import ErrorForFormHook from '@components/ErrorForFormHook';
import { useQuery } from '@apollo/client';
import { READ_MEDIA_URLS } from '@schemas/mutations/image';
import UploadErrorModal from '@components/pages/PublishRealEstate/components/UploadErrorModal';

type Props = {
  description?: string,
  errorMessage?: string,
  allowVideo?: boolean,
  mediaUrls?: Array,
  errors?: Array,
  setMediaUrls?: Function,
  maxFiles?: number,
  name?: string,
};

export default function UploadImage(props: Props) {
  const {
    description,
    allowVideo,
    mediaUrls = [],
    setMediaUrls = () => {},
    maxFiles = 20,
    name,
    errors,
  } = props;

  const [openModal, setOpenModal] = useState(false);

  const { doUploadImage, removeFile } = useUploadImage({
    maxFiles,
    mediaUrls,
    setOpenModal,
    setMediaUrls,
    fieldName: name,
    maxImage: maxFiles,
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*', ...(allowVideo ? ['video/*'] : [])],
    multiple: true,
    onDrop: doUploadImage,
    disabled: mediaUrls.length >= maxFiles,
  });

  const onRemove = useCallback((fileId) => {
    removeFile(fileId);
  }, []);

  const renderResult = useMemo(() => {
    return ({ files = [], removeFile = () => {} }) => {
      if (files?.length) {
        return (
          <div className={styles.imgContainer}>
            {files?.map((item) => {
              return (
                <div
                  className={styles.imgItem}
                  key={item.fileId}
                  style={{ margin: 12 }}>
                  <Image
                    {...item}
                    wrapperStyle={`mr-0 ${styles.img}`}
                    remove={() => removeFile(item.fileId)}
                  />
                </div>
              );
            })}
          </div>
        );
      }
    };
  }, []);

  return (
    <div className={styles.uploadImgContainer}>
      <p className="text-14 font-text text-blue-main text-center">
        {description}
      </p>
      <div
        {...getRootProps({
          className: `dropzone ${styles.uploadButton}`,
        })}>
        <input
          {...getInputProps()}
          // multiple={false}
          style={{ display: 'none' }}
        />
        <img
          src="/svg/plusBlue.svg"
          className="w-16px h-16px mr-8px"
          alt="icon"
        />
        <p className="text-14 font-text text-blue-main">Chọn ảnh tải lên</p>
      </div>
      {renderResult({ files: mediaUrls, removeFile: onRemove })}
      <ErrorForFormHook name={name} errors={errors} wrapperClass="mt-8px" />
      <UploadErrorModal open={openModal} onClose={() => setOpenModal(false)} />
    </div>
  );
}

type ImageProps = {
  fileId?: string,
  remove?: Function,
  loading?: Boolean,
  progress?: number,
};

function Image({ fileId, remove, loading, progress }: ImageProps) {
  const { data } = useQuery(READ_MEDIA_URLS, {
    variables: {
      fileId,
    },
    skip: !fileId,
  });

  const originalUrl = data?.readMediaUrls?.originalUrl;

  return (
    <div
      style={{ backgroundImage: `url("${originalUrl}")` }}
      className={styles.image}>
      {loading && (
        <div
          className="flex rounded-rd8px flex-col w-full h-full absolute items-center justify-center"
          style={{
            backgroundColor: 'rgba(0,0,0,0.4)',
          }}>
          <div className="text-white" style={{ fontSize: 11 }}>
            {parseInt(progress * 100, 10)}%
          </div>
        </div>
      )}
      <button
        onClick={remove}
        className="bg-white rounded-rd50 border border-gray-300 h-24px w-24px absolute -top-12px -right-12px bg-no-repeat bg-cover bg-center"
        style={{ backgroundImage: 'url("/svg/closeIcon.svg")' }}
      />
    </div>
  );
}
