import styles from '@styles/components/SelectComponent.module.scss';
import React, { useMemo, useState } from 'react';
import MyInput from '@components/Input';
import useDebounce from 'react-use/lib/useDebounce';
import Fuse from 'fuse.js';
import Radio from '@components/Radio';
type SelectComponentProps = {
  context?: Object,
  close?: Function,
};

type SelectItemProps = {
  textStyle?: string,
  onClick?: Function,
  value?: string,
  hasItemRadio?: boolean,
  isChecked?: boolean,
};

function SelectItem({
  textStyle,
  onClick,
  value,
  hasItemRadio,
  isChecked,
}: SelectItemProps) {
  return !hasItemRadio ? (
    <div onClick={onClick} className={`${styles.selectItem} ${textStyle}`}>
      {value}
    </div>
  ) : (
    <div onClick={onClick} className={`${styles.selectItemRadio}`}>
      <Radio
        isChecked={isChecked}
        title={value}
        onPress={onClick}
        wrapperStyle="ml-2.5"
      />
    </div>
  );
}

export default function SelectComponent(props: SelectComponentProps) {
  const {
    context: {
      configs = [],
      value,
      setValue,
      isShowSearchBox,
      getObject = false,
      hasItemRadio = false,
      loading,
    },
    close,
  } = props;
  const [search, setSearch] = useState('');
  const [debounce, setDebounce] = useState('');
  useDebounce(
    () => {
      setDebounce(search);
    },
    300,
    [search],
  );

  const options = {
      includeScore: true,
      keys: ['value'],
      threshold: 0.5,
      includeMatches: true,
      ignoreLocation: true,
      distance: 600,
    },
    fuse = new Fuse(configs, options),
    result = fuse.search(debounce);

  const finalSelections = useMemo(() => {
    return debounce !== '' ? result?.map((e) => e.item) : [...(configs || [])];
  }, [debounce, result, configs]);
  return (
    <div className={styles.dropdownContainer}>
      {isShowSearchBox && (
        <div
          style={{ zIndex: 1000 }}
          className="flex-1 sticky top-0 bg-white p-2 border-b border-gray-200">
          <MyInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Nhập từ khóa để tìm kiếm"
            onDelete={() => setSearch('')}
          />
        </div>
      )}
      {!loading &&
        finalSelections?.map((item, index) => {
          const isActive = item.key === value;
          const lastItem = index === finalSelections.length - 1;
          return (
            <SelectItem
              hasItemRadio={hasItemRadio}
              isChecked={isActive}
              key={index}
              onClick={() => {
                if (!getObject) {
                  setValue(item.key);
                } else {
                  setValue(item);
                }
                close();
              }}
              value={item.value}
              textStyle={`${
                isActive ? `text-blue-main font-semibold` : 'text-black'
              } ${!lastItem ? 'border-b border-gray-300' : undefined}`}
            />
          );
        })}
      {loading && (
        <div className="flex flex-1 flex-row items-center justify-center space-x-1 py-4">
          <div className="text-sm text-blue-main">Đang tải</div>
          <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-main" />
        </div>
      )}
    </div>
  );
}
