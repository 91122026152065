type RadioProps = {
  title?: string,
  isChecked?: boolean,
  wrapperStyle?: string,
  titleStyle?: string,
  onPress?: Function,
  disabled?: boolean,
};

export default function Radio(props: RadioProps) {
  const { title, isChecked, wrapperStyle, titleStyle, onPress, disabled } =
      props,
    src = isChecked ? '/svg/rad_checked.svg' : '/svg/unChecked.svg';
  return (
    <div
      className={`flex flex-row items-center space-x-2 cursor-pointer ${
        disabled ? 'pointer-events-none' : undefined
      } ${wrapperStyle}`}
      onClick={() => {
        onPress && onPress();
      }}>
      <img style={{ width: 20, height: 20 }} alt="check" src={src} />
      <div className={`text-13 text-black ${titleStyle}`}>{title}</div>
    </div>
  );
}
