import { companyFieldEnumType } from '@utils/configs/UserConfiguarations/userConfigs';
import Checkbox from '@components/checkbox';
import { useCallback } from 'react';
import dynamic from 'next/dynamic';
import { Controller, useFormContext } from 'react-hook-form';
import MyInputV2 from '@components/inputV2';

const ErrorForFormHook = dynamic(() => import('@components/ErrorForFormHook'), {
  ssr: false,
});

type Props = {
  value?: Array,
  onChange?: Function,
  errors?: Object,
  innerClass?: string,
  disabled?: boolean,
};

export default function CompanyField(props: Props) {
  const { value = [], onChange, errors, innerClass, disabled } = props;

  const { control } = useFormContext();

  const isCheckOtherCompany = value?.includes('other');

  const checkItem = useCallback(
    (isChecked, key) => {
      const cloneValue = [...value];
      if (isChecked) {
        onChange(cloneValue.filter((e) => e !== key));
      } else {
        onChange([...cloneValue, key]);
      }
    },
    [value, onChange],
  );

  return (
    <div className="flex flex-col flex-1">
      <div className={`grid gap-4 grid-cols-2 ${innerClass}`}>
        {companyFieldEnumType.map((item) => {
          const isChecked = value?.includes(item.key);
          return (
            <Checkbox
              isChecked={value?.includes(item.key)}
              key={item.key}
              title={item.value}
              value={item.key}
              onPress={() => checkItem(isChecked, item.key)}
            />
          );
        })}
      </div>
      {isCheckOtherCompany && (
        <Controller
          control={control}
          render={({
            field: { value, onChange },
            fieldState: { invalid },
            formState: { errors },
          }) => (
            <MyInputV2
              inputClass="h-40px"
              errors={errors}
              name="otherCompany"
              invalid={invalid}
              inputOuterClassName="flex-1"
              inputClassName="flex flex-row items-center mt-16px"
              placeholder="Nhập giá tri"
              isRequire
              disabled={disabled}
              value={value}
              showClose={!disabled}
              onChange={(e) => onChange(e.target.value.substr(0, 100))}
              autoComplete="new-password"
            />
          )}
          name="otherCompany"
        />
      )}
      <ErrorForFormHook errors={errors} name="companyFields" />
    </div>
  );
}
