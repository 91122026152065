import React from 'react';
import { useRouter } from 'next/router';

type Props = {
  title?: string,
  renderBottomComponent?: Function,
  renderRightComponent?: Function,
  onRowClick?: Function,
  wrapperClass?: string,
  arrowStyle?: string,
  isCloseWindow?: string,
};

export default function PageTitle2(props: Props) {
  const router = useRouter(),
    {
      title,
      isCloseWindow,
      renderBottomComponent,
      renderRightComponent,
      onRowClick = () => router.back(),
      wrapperClass,
      arrowStyle,
    } = props;
  return (
    <div
      className={`flex lg:hidden flex-col sticky top-0 w-full border-b border-gray-200 bg-white z-10 ${wrapperClass}`}
      style={{ minHeight: 'fit-content' }}>
      <div className="flex flex-row items-center py-8px justify-between">
        <button
          onClick={() => {
            isCloseWindow === 'true' ? window.close() : onRowClick();
          }}
          className="w-48px h-32px bg-blue-main flex flex-col items-center justify-center"
          style={{ borderTopRightRadius: 16, borderBottomRightRadius: 16 }}>
          <img
            src="/svg/arrowDown.svg"
            alt="icon arrow"
            className={`w-16px h-16px ${
              arrowStyle ? arrowStyle : `transform rotate-0`
            }`}
          />
        </button>
        <p className="text-16 font-bold font-textBold text-blue-main mx-32px text-center">
          {title}
        </p>
        {renderRightComponent ? (
          renderRightComponent()
        ) : (
          <div className="w-48px" />
        )}
      </div>
      {renderBottomComponent && renderBottomComponent()}
    </div>
  );
}
