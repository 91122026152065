export const hotline = '0987654321';
export const emailSupport = 'hotro@realtech247.com';
export const webName = 'RealTech247';

export const HCMId = '60a0f43d6a8ebbd4e72f29c9',
  Q1 = '60a0fe5b6a8ebbd4e7325c28',
  pBenNghe = '60a346e56a8ebbd4e79723f1';

export const maxLength = {
  email: 68,
  taxId: 15,
  password: 32,
  name: 118,
  phoneNumber: 10,
  bankCode: 20,
  width: 8,
  length: 9,
  rear: 10,
  violatedLand: 16,
  developerCode: 20,
  otpCode: 6,
  idNumber: 12,
};

export const m2 = 'm²';

export const dateFormat = 'HH:mm, DD/MM/YYYY';

export const Emails = [
  'ledutuyetnhi1810.ggt@gmail.com',
  'info@gugotech.com',
  'phamtung7879@gugotech.com',
];

export const allProvince = [
  '60a0f43d6a8ebbd4e72f29c9',
  '60b5e46985933a3acef9edba',
  '60b602a89dd6c1013f93ebbe',
  '60b603129dd6c1013f93f3bc',
  '60b62c0b9dd6c1013f966037',
  '610cbddd977b3270637b992f',
  '610d0c6b977b3270637f95b2',
  '610d0c7b977b3270637fa4c2',
  '610d0cc8977b3270637fefc9',
  '610d0ce8977b327063800fa6',
  '610d19b4977b32706380c40f',
  '610d19cc977b32706380db33',
  '610d19d8977b32706380e6db',
  '610d19ed977b32706380fc20',
  '610d1a01977b327063811015',
  '610d1a08977b3270638116b6',
  '610d1a30977b327063813eac',
  '610d1a3f977b327063814d86',
  '6111244e977b327063b135eb',
  '6111246e977b327063b15525',
  '611124c9977b327063b1b1d3',
  '611128eb977b327063b1f8f5',
  '611128fe977b327063b20b32',
  '6111290a977b327063b2177a',
  '61112916977b327063b222a4',
  '61112927977b327063b23455',
  '61112941977b327063b24efe',
  '6111294d977b327063b25c34',
  '6111295b977b327063b269fe',
  '611258f2977b327063c0b16d',
  '61125900977b327063c0bdcc',
  '6112591e977b327063c0d9a8',
  '61125926977b327063c0e144',
  '6112593b977b327063c0f6e5',
  '61125947977b327063c10269',
  '61125960977b327063c11a92',
  '61125970977b327063c12a6b',
  '61125994977b327063c14c4a',
  '611259a5977b327063c15cb1',
  '611259b7977b327063c16e1b',
  '611259bf977b327063c175e3',
  '611259c8977b327063c17e84',
  '611259dd977b327063c19267',
  '611259fe977b327063c1b201',
  '61125a0c977b327063c1c025',
  '61125a28977b327063c1dbf8',
  '61125a35977b327063c1e92b',
  '61125a4a977b327063c1fe86',
  '61125a6c977b327063c21f3d',
  '61125aa6977b327063c258e4',
  '61125abc977b327063c26d22',
  '61125ad3977b327063c2845a',
  '61125ae4977b327063c294ef',
  '61125afd977b327063c2acf2',
  '61125b09977b327063c2b8f0',
  '61125b1b977b327063c2ca45',
  '61125b25977b327063c2d336',
  '61125b40977b327063c2eed9',
  '61125b54977b327063c301ea',
  '61125b70977b327063c31da5',
  '61125b8e977b327063c33acf',
  '61125ba5977b327063c350fc',
  '61150459977b327063ec8715',
];
