import styles from '@styles/components/PublistRealEstate/DropdownForPublish.module.scss';
import React, { useEffect, useState } from 'react';
import Dropdown from '@components/Dropdown';
import SelectComponent from '@components/SelectComponent';
import useLazyModal from '@hooks/common/useLazyModal';
import DrawerSelection from '@components/DrawerSelection';
import ArrowDown from '@public/svg/arrowV2.svg';
import DrawerWrapper from '@components/DrawerWrapper';
type Props = {
  value?: string,
  setValue?: Function,
  configs?: Array,
  buttonDropdownClassName?: string,
  placeholder?: string,
  onDelete?: Function,
  errorMsg?: string,
  isShowSearchBox?: boolean,
  hideDeleteButton?: boolean,
  customValue?: Function,
  disabled?: boolean,
  title?: string,
  wrapperStyleMobile?: string,
  getObject?: boolean,
  isBigArrow?: boolean,
  hasItemRadio?: boolean,
  loading?: boolean,
};

type ContentProps = {
  customValue?: Function,
  renderValue?: string,
  value?: string,
  hideDeleteButton?: boolean,
  onDelete?: Function,
  setDisableDropdown?: Function,
  toggle?: boolean,
  disabled?: boolean,
  isBigArrow?: boolean,
  placeholder?: string,
};
export function Content({
  customValue,
  renderValue,
  value,
  hideDeleteButton,
  onDelete,
  setDisableDropdown,
  toggle,
  disabled,
  isBigArrow,
  placeholder = 'Chọn giá trị',
}: ContentProps) {
  return (
    <div className="w-full flex flex-row items-center justify-between">
      {customValue && customValue()}
      {!customValue && (
        <div
          className={`flex-1 text-left text-sm truncate ${
            !value && renderValue !== 'Tất cả'
              ? 'text-gray-400'
              : 'text-blue-main'
          }`}>
          {renderValue || placeholder}
        </div>
      )}
      <div className="flex flex-row items-center space-x-2 mr-2">
        {value && !hideDeleteButton && (
          <div
            onClick={() => {
              onDelete && !disabled && onDelete();
              setDisableDropdown(true);
              setTimeout(() => {
                setDisableDropdown(false);
              }, 100);
            }}
            className="w-5 h-5 cursor-pointer">
            <img
              className="w-full h-full ml-1"
              alt="close"
              src="/svg/ico-close.svg"
            />
          </div>
        )}
        {isBigArrow ? (
          <ArrowDown
            fill="#888"
            className={`${
              toggle ? 'transform rotate-180 duration-300' : 'duration-300'
            }`}
          />
        ) : (
          <img
            alt="arrow"
            src="/svg/arrowDownBlue.svg"
            className={`${
              toggle ? 'transform rotate-180 duration-300' : 'duration-300'
            }`}
          />
        )}
      </div>
    </div>
  );
}

export default React.forwardRef(MyDropdownForPublish);

function MyDropdownForPublish(props: Props, ref) {
  const {
    placeholder,
    value,
    setValue,
    configs,
    buttonDropdownClassName,
    onDelete,
    errorMsg,
    isShowSearchBox,
    hideDeleteButton = false,
    customValue,
    disabled,
    title,
    wrapperStyleMobile,
    getObject = false,
    isBigArrow = false,
    hasItemRadio,
    loading,
  } = props;
  const [toggle, onToggle] = useState(false);
  const [toggleDrawer, onToggleDrawer] = useState(false);
  const { renderModal, onHover } = useLazyModal();
  const [disableDropdown, setDisableDropdown] = useState(false);
  const renderValue = !configs?.find((e) => e.key === value)?.value
    ? placeholder
    : configs?.find((e) => e.key === value)?.value || placeholder;
  const isMobile = process.browser && window.innerWidth <= 600;
  useEffect(() => {
    toggleDrawer
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [toggleDrawer]);
  return (
    <button
      ref={ref}
      className={`w-full space-y-1 ${
        errorMsg?.length > 0 ? 'errorInput' : ''
      }`}>
      {!isMobile ? (
        <Dropdown
          disabled={disableDropdown || disabled}
          show={toggle}
          onToggle={() => onToggle(!toggle)}
          DropdownComponent={SelectComponent}
          dropdownContext={{
            value,
            setValue,
            configs,
            isShowSearchBox,
            getObject,
            hasItemRadio,
            loading,
          }}
          style={`${styles.buttonContainer} ${
            errorMsg?.length ? 'border-red-400' : 'border-blue-main'
          } ${
            disabled ? 'bg-gray-100' : undefined
          } ${buttonDropdownClassName}`}>
          <Content
            isBigArrow={isBigArrow}
            disabled={disabled}
            toggle={toggle}
            customValue={customValue}
            value={value}
            hideDeleteButton={hideDeleteButton}
            onDelete={onDelete}
            renderValue={renderValue}
            setDisableDropdown={setDisableDropdown}
            placeholder={placeholder}
          />
        </Dropdown>
      ) : (
        <button
          ref={ref}
          onMouseEnter={onHover}
          onClick={() => onToggleDrawer(!toggleDrawer)}
          className={`${styles.buttonContainer} ${
            errorMsg?.length ? 'border-red-400' : 'border-blue-main'
          } ${
            disabled ? 'bg-gray-100 pointer-events-none' : undefined
          } ${buttonDropdownClassName}`}>
          <Content
            isBigArrow={isBigArrow}
            disabled={disabled}
            toggle={toggleDrawer}
            customValue={customValue}
            value={value}
            hideDeleteButton={hideDeleteButton}
            onDelete={onDelete}
            renderValue={renderValue}
            setDisableDropdown={setDisableDropdown}
          />
        </button>
      )}
      {(renderModal || toggleDrawer) && !disableDropdown && (
        <DrawerWrapper
          open={toggleDrawer}
          onClose={() => onToggleDrawer(false)}
          container={process.browser && document.getElementById('modal-root')}
          className="w-full h-full">
          <DrawerSelection
            loading={loading}
            getObject={getObject}
            wrapperStyle={wrapperStyleMobile}
            title={title}
            value={value}
            setValue={setValue}
            configs={configs}
            isShowSearchBox={isShowSearchBox}
            close={() => onToggleDrawer(false)}
          />
        </DrawerWrapper>
      )}
      {errorMsg && (
        <div className="text-left text-sm text-red-400">{errorMsg}</div>
      )}
    </button>
  );
}
