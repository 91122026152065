import { AuthenticationMobileContext } from '@components/AuthenticationMobile/context';
import React, { useCallback, useState, useImperativeHandle } from 'react';
import DrawerWrapper from '@components/DrawerWrapper';
import dynamic from 'next/dynamic';

const Login = dynamic(
  () => import('@components/AuthenticationMobile/Component/Login'),
  { ssr: false },
);
const SignUp = dynamic(
  () => import('@components/AuthenticationMobile/Component/SignUp'),
  { ssr: false },
);

const ModalWrapper = dynamic(() => import('@components/ModalWrapper'), {
  ssr: false,
});
const SignUpSuccess = dynamic(
  () => import('@components/modals/ModalSignInSignUp/components/signUpSuccess'),
  { ssr: false },
);
import styles from '@styles/components/ModalSignInSignUp/SignUpMobile.module.scss';
import { SignInContext } from '@components/modals/ModalSignInSignUp/context';
import ChangePassword from '@components/AuthenticationMobile/Component/ChangePassword';
import UpgradeAccountForMobile from '@components/UpdatePremiumAccount/UpgradeAccountForMobile';
import SendOTPError from '@components/VerifyPhone/components/SendOTPError';

const ForgotPassword = dynamic(
  () => import('@components/AuthenticationMobile/Component/ForgotPassword'),
  { ssr: false },
);

type Props = {
  showLogin?: boolean,
  setShowLogin?: Function,
  showSignUp?: boolean,
  setShowSignUp?: Function,
  showChangePassword?: boolean,
  setShowChangePassword?: Function,
};

export default React.forwardRef(AuthenticationMobile);

function AuthenticationMobile(props: Props, ref) {
  const {
    showLogin,
    setShowLogin,
    showSignUp,
    setShowSignUp,
    showChangePassword,
    setShowChangePassword,
  } = props;

  const [showModalSignUpSuccess, setShowModalSignUpSuccess] = useState(false),
    [signUpResult, setSignUpResult] = useState(null),
    [socialData, setSocialData] = useState({});

  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [showUpdateAcc, setShowUpdateAccount] = useState(false);

  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const toggleForgotPassword = useCallback((flag) => {
    setShowForgotPassword(flag);
  }, []);

  const closeLogin = useCallback(() => {
    setShowLogin(false);
  }, []);

  const closeSignUp = useCallback(() => {
    setShowSignUp(false);
    setSocialData({});
  }, []);

  useImperativeHandle(ref, () => ({
    toggleForgotPassword,
  }));

  return (
    <AuthenticationMobileContext.Provider
      value={{
        showLogin,
        setShowLogin,
        closeLogin,
        closeSignUp,
        setShowSignUp,
        setShowModalSignUpSuccess,
        setSignUpResult,
        openForgotPassword: () => toggleForgotPassword(true),
        closeForgotPassword: () => toggleForgotPassword(false),
      }}>
      <SignInContext.Provider
        value={{ socialData, setSocialData, setOpenModal, setErrorMessage }}>
        {showLogin && (
          <DrawerWrapper
            open={showLogin}
            onClose={closeLogin}
            className="w-full h-full"
            customPercentTopAnchorBottom="5%">
            <Login close={closeLogin} />
          </DrawerWrapper>
        )}
        {showSignUp && (
          <DrawerWrapper
            open={showSignUp}
            onClose={closeSignUp}
            className="w-full h-full"
            customPercentTopAnchorBottom="5%">
            <SignUp close={closeSignUp} />
          </DrawerWrapper>
        )}
        {showForgotPassword && (
          <DrawerWrapper
            open={showForgotPassword}
            onClose={() => toggleForgotPassword(false)}
            className="w-full h-full"
            customPercentTopAnchorBottom="5%">
            <ForgotPassword close={() => toggleForgotPassword(false)} />
          </DrawerWrapper>
        )}
        {showChangePassword && (
          <DrawerWrapper
            open={showChangePassword}
            onClose={() => setShowChangePassword(false)}
            className="w-full h-full"
            customPercentTopAnchorBottom="5%">
            <ChangePassword close={() => setShowChangePassword(false)} />
          </DrawerWrapper>
        )}
        <UpgradeAccountForMobile
          openModal={showUpdateAcc}
          setOpenModal={setShowUpdateAccount}
        />
      </SignInContext.Provider>
      {showModalSignUpSuccess && (
        <ModalWrapper
          className="rounded-rd4px max-w-95%"
          open={showModalSignUpSuccess}
          onClose={() => setShowModalSignUpSuccess(false)}>
          <SignUpSuccess
            close={() => {
              setShowModalSignUpSuccess(false);
              setShowUpdateAccount(true);
            }}
            configs={{ ...signUpResult }}
            wrapperClass={styles.containerNotification}
          />
        </ModalWrapper>
      )}
      <ModalWrapper
        className="rounded-rd8px"
        open={openModal}
        onClose={() => setOpenModal(false)}>
        <SendOTPError
          close={() => setOpenModal(false)}
          errorMessage={errorMessage}
        />
      </ModalWrapper>
    </AuthenticationMobileContext.Provider>
  );
}
