import { Controller, useFormContext } from 'react-hook-form';
import moment from 'moment/moment';
import ArrowDown from '@public/svg/arrowV2.svg';
import React, { useState } from 'react';
import DatePicker from '@components/datePicker';
import Dropdown from '@components/Dropdown';
import ErrorForFormHook from '@components/ErrorForFormHook';
import useToggle from 'react-use/lib/useToggle';
import DrawerWrapper from '@components/DrawerWrapper';
import { isMobile } from '@utils/helper';

type Props = {
  name?: string,
};

const dateFormat = 'YYYYMMDD';

export default function SelectBirthDate(props: Props) {
  const { name } = props;

  const [toggle, setToggle] = useToggle(false);
  const [drawerCalendar, setDrawCalendar] = useState(false);

  const { control, watch, setValue } = useFormContext();

  const crValue = watch(name);

  return (
    <>
      <Controller
        control={control}
        render={({
          field: { value, onChange },
          formState: { errors },
          fieldState: { error },
        }) => {
          return (
            <div className="flex flex-1 flex-col">
              <button
                onClick={() => {
                  if (isMobile) {
                    setDrawCalendar(true);
                  }
                }}
                className={`w-full h-40px rounded-rd8px border items-center flex ${
                  error?.message?.length ? 'border-red-400' : 'border-blue-main'
                }`}
                style={{ padding: '0 5px' }}>
                <Dropdown
                  disabled={isMobile}
                  onToggle={setToggle}
                  style="lg:flex hidden w-full justify-between items-center"
                  DropdownComponent={DatePicker}
                  dropdownContext={{
                    value: moment(value, dateFormat),
                    setValue: onChange,
                    showHour: false,
                  }}>
                  <RenderDate value={value} toggle={toggle} />
                </Dropdown>
                <div className="flex lg:hidden w-full h-full items-center justify-between">
                  <RenderDate value={value} />
                </div>
              </button>
              <ErrorForFormHook name={name} errors={errors} />
            </div>
          );
        }}
        name={name}
      />
      <DrawerWrapper
        open={drawerCalendar}
        onClose={() => setDrawCalendar(false)}
        className="w-full h-full">
        <div className="w-full">
          <DatePicker
            wrapperClass="h-full border-0"
            close={() => setDrawCalendar(false)}
            context={{
              value: moment(crValue, dateFormat),
              setValue: (nextValue) => setValue(name, nextValue),
              showHour: false,
            }}
          />
        </div>
      </DrawerWrapper>
    </>
  );
}

type RenderDateProps = {
  value?: string,
  toggle?: boolean,
};

function RenderDate({ value, toggle }: RenderDateProps) {
  return (
    <>
      <p className="bodyText">
        {moment(value, dateFormat).format('DD/MM/YYYY')}
      </p>
      <ArrowDown
        fill="#888"
        className={`${
          toggle ? 'transform rotate-180 duration-300' : 'duration-300'
        }`}
      />
    </>
  );
}
