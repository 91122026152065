import type { InputV2Props } from '@components/inputV2/index';
import MyInputV2 from '@components/inputV2/index';
import useToggle from 'react-use/lib/useToggle';
import dynamic from 'next/dynamic';

const IconClosePassword = dynamic(
  () => import('@public/svg/closePassword.svg'),
  { ssr: false },
);
const IconOpenPassword = dynamic(() => import('@public/svg/openPassword.svg'), {
  ssr: false,
});

type Props = {} & InputV2Props;

export default function SecureInput({ ...rest }: Props) {
  const [secure, setSecure] = useToggle(true);

  const Icon = secure ? IconClosePassword : IconOpenPassword;

  return (
    <MyInputV2
      {...rest}
      type={secure ? 'password' : 'string'}
      renderRightComponent={() => (
        <button
          onClick={setSecure}
          className="w-24px h-24px"
          style={{ marginRight: 5 }}>
          <Icon className="w-full h-full" />
        </button>
      )}
    />
  );
}
