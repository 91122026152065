import { useCallback } from 'react';

export const useChangeMonth = ({ setMonth, setYear, year }) => {
  const increaseMonth = useCallback(() => {
    setMonth((cr) => {
      if (cr === 12) {
        setYear(year + 1);
        return 1;
      } else {
        return cr + 1;
      }
    });
  }, [setMonth, setYear]);

  const decreaseMonth = useCallback(() => {
    setMonth((cr) => {
      if (cr === 1) {
        setYear(year - 1);
        return 12;
      } else {
        return cr - 1;
      }
    });
  }, [setMonth, setYear, year]);

  return { increaseMonth, decreaseMonth };
};
