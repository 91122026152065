import React from 'react';

export const HousingCalendarMobileContext = React.createContext({
  daysInMonth: [],
  month: '',
  year: '',
  setMonth: () => {},
  setYear: () => {},
  dateViewAppointment: null,
  setDateViewAppointment: () => {},
  setShowCreateSuccess: () => {},
  setShowUpdateSuccess: () => {},
  setParamDelete: () => {},
  setShowModalDelete: () => {},
});

export const useHosingCalendarMobileContext = () =>
  React.useContext(HousingCalendarMobileContext);
