import styles from '@styles/components/DatePicker.module.scss';
import { getListDaysInMonth } from '@utils/helper';
import moment, { Moment } from 'moment/moment';
import React, { useCallback, useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import MyButton from '@components/Button';
import { useChangeMonth } from '@hooks/Appointment/useChangeMonth';
import Dropdown from '@components/Dropdown';
import Selector from '@components/datePicker/Selector';
import { useHosingCalendarMobileContext } from '@components/pages/HousingCalendarMobile/context';

const Arrow = dynamic(() => import('@public/svg/arrowV2.svg'), { ssr: false });

const dateOfWeek = ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'];

const dateFormat = 'DD-MM-YYYY';

const dateFormatCompare = 'YYYY-MM-DD';

let monthList = [],
  yearList = [];

for (let i = 1; i <= 12; i += 1) {
  monthList.push(i);
}

for (let i = moment().get('year'); i >= 1945; i -= 1) {
  yearList.push(i);
}

type Props = {
  close?: Function,
  wrapperClass?: string,
  context?: {
    value?: Moment,
    setValue?: Function,
    showHour?: boolean,
  },
};

export default function DatePicker(props: Props) {
  const {
    wrapperClass,
    close,
    // context: { value = moment(), showHour = true },
    context: { value = moment(), setValue, showHour = true },
  } = props;

  const [month, setMonth] = useState(value.month() + 1),
    [year, setYear] = useState(value.year());

  useEffect(() => {
    if (value.isValid()) {
      setMonth(value.month() + 1);
      setYear(value.year());
    }
  }, [value]);

  const listDays = getListDaysInMonth(month, year);
  const [date, setDate] = useState(value.format(dateFormat)),
    [hour, setHour] = useState(moment().hour()),
    [minute, setMinute] = useState(moment().minute());

  const { setDateViewAppointment } = useHosingCalendarMobileContext();

  const onSelectTime = useCallback(() => {
    const nextDate = moment(moment(date, dateFormat))
      .set('hour', hour)
      .set('minute', minute);
    setDateViewAppointment(nextDate);
    setValue(nextDate);
    close();
  }, [date, hour, minute, close]);

  const { increaseMonth, decreaseMonth } = useChangeMonth({
    setMonth,
    setYear,
    year,
  });

  return (
    <div className={`${styles.container} ${wrapperClass}`}>
      <div className={styles.calendarContainer}>
        <div className="w-full flex flex-row mb-16px items-center">
          <div className="heading4 flex">
            Tháng{' '}
            <div className="ml-4px">
              <Dropdown
                style="flex items-center w-50px"
                DropdownComponent={Selector}
                dropdownContext={{
                  selections: monthList,
                  value: month,
                  setValue: setMonth,
                }}>
                {month}
                <img
                  alt="icon"
                  src="/svg/arrowBack.svg"
                  className="w-16px h-16px transform -rotate-90"
                />
              </Dropdown>
            </div>{' '}
            <div>
              <Dropdown
                style="flex items-center w-50px"
                DropdownComponent={Selector}
                dropdownContext={{
                  selections: yearList,
                  value: year,
                  setValue: setYear,
                }}>
                {year}
                <img
                  alt="icon"
                  src="/svg/arrowBack.svg"
                  className="w-16px h-16px transform -rotate-90"
                />
              </Dropdown>
            </div>
          </div>
          <div className="flex-1" />
          <button
            className={`${styles.buttonChangeMonth} mr-8px`}
            onClick={decreaseMonth}>
            <img
              src="/svg/arrowBack.svg"
              className="w-16px h-16px"
              alt="ico-arrow"
            />
          </button>
          <button
            onClick={increaseMonth}
            className={`${styles.buttonChangeMonth} transform rotate-180`}>
            <img
              src="/svg/arrowBack.svg"
              className="w-16px h-16px"
              alt="ico-arrow"
            />
          </button>
        </div>
        <div className="grid gap-2 grid-cols-2 grid-cols-7">
          {dateOfWeek.map((item) => {
            return (
              <div key={item} className="flex w-full justify-center">
                <div
                  className={`${styles.itemDate} font-bold text-14 font-textBold`}>
                  {item}
                </div>
              </div>
            );
          })}
        </div>
        <div className="grid gap-2 grid-cols-2 grid-cols-7 mt-8px">
          {listDays.map((item, index) => {
            return (
              <div key={index} className="flex w-full justify-center">
                <ItemDate
                  month={month}
                  year={year}
                  date={date}
                  item={item}
                  onClick={() => {
                    setDate(item);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      {showHour && (
        <Time
          hour={hour}
          setHour={setHour}
          minute={minute}
          setMinute={setMinute}
        />
      )}
      <MyButton
        label="Chọn thời gian"
        wrapperClass="mt-16px"
        onPress={() => {
          onSelectTime();
        }}
      />
    </div>
  );
}

type ItemDateProps = {
  item?: string,
  date?: string,
  year?: number,
  month?: number,
};

function ItemDate({ item, date, month, year, ...rest }: ItemDateProps) {
  const today = moment(moment().format(dateFormatCompare)).isSame(
    moment(item, dateFormat).format(dateFormatCompare),
  );

  const dateSelected = moment(
    moment(date, dateFormat).format(dateFormatCompare),
  ).isSame(moment(item, dateFormat).format(dateFormatCompare));

  const dateOfCurrentMonth = moment(
    moment(`${year}-${month}`, 'YYYY-MM').format('YYYY-MM'),
  ).isSame(moment(item, dateFormat).format('YYYY-MM'));

  return (
    <button
      {...rest}
      className={`${styles.itemDate} ${today && styles.today} ${
        dateSelected && styles.dateSelected
      } ${!dateOfCurrentMonth && 'text-gray-400'}`}>
      {moment(item, dateFormat).get('date')}
    </button>
  );
}

type TimeProps = {
  hour?: string,
  setHour?: Function,
  minute?: string,
  setMinute?: Function,
};

function Time(props: TimeProps) {
  const { hour, setHour, minute, setMinute } = props;

  const inCreaseHour = useCallback(() => {
    setHour((cr) => {
      if (cr === 23) {
        return 0;
      }
      return cr + 1;
    });
  }, []);

  const deCreaseHour = useCallback(() => {
    setHour((cr) => {
      if (cr === 0) {
        return 23;
      }
      return cr - 1;
    });
  }, []);

  const inCreaseMinute = useCallback(() => {
    setMinute((cr) => {
      if (cr === 59) {
        return 0;
      }
      return cr + 1;
    });
  }, []);

  const deCreaseMinute = useCallback(() => {
    setMinute((cr) => {
      if (cr === 0) {
        return 59;
      }
      return cr - 1;
    });
  }, []);

  return (
    <div className="flex flex-row items-center mt-16px w-full justify-center">
      <div className="flex flex-col items-center mx-24px space-y-2">
        <button onClick={inCreaseHour}>
          <Arrow className="w-11px h-6px transform rotate-180" fill="#000" />
        </button>
        <div className={`${styles.boxTime}`}>
          {hour > 9 ? hour : `0${hour}`}
        </div>
        <button onClick={deCreaseHour}>
          <Arrow className="w-11px h-6px" fill="#000" />
        </button>
      </div>
      <div className="heading4 mr-24px">:</div>
      <div className="flex flex-col items-center space-y-2">
        <button onClick={inCreaseMinute}>
          <Arrow className="w-11px h-6px transform rotate-180" fill="#000" />
        </button>
        <div className={styles.boxTime}>
          {minute > 9 ? minute : `0${minute}`}
        </div>
        <button onClick={deCreaseMinute}>
          <Arrow className="w-11px h-6px" fill="#000" />
        </button>
      </div>
    </div>
  );
}
