import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import {
  GET_USER_BASIC_INFO,
  MY_PROFILE_REQUEST,
} from '@schemas/queries/users';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { upgradePremiumAccountSchema } from '@utils/validation/auth';
import { getErrorsFormHook } from '@utils/helper';
import { HCMId } from '@utils/configs/Common/CommonConfigs';
import { companyFieldEnumType } from '@utils/configs/UserConfiguarations/userConfigs';

const blankState = {
  fullName: '',
  province: '',
  birthDate: '',
  establishedDate: '',
  idNumber: '',
  company: '',
  companyName: '',
  email: '',
  avatar: '',
  gender: 'male',
  description: '',
  frontIdImages: [],
  backIdImages: [],
  companyLicense: [],
};

const formatDate1 = 'YYYYMMDD';

const formatRawData = (remoteValue) => {
  const birthDate = remoteValue?.birthDate?.length
      ? moment(remoteValue?.birthDate, 'YYYY-MM-DD').format(formatDate1)
      : moment(),
    establishedDate = remoteValue?.establishedDate
      ? moment(remoteValue?.establishedDate, 'YYYY-MM-DD').format(formatDate1)
      : moment();

  const otherCompany = remoteValue?.companyFields?.filter(
    (e) => !companyFieldEnumType.map((item) => item.key).includes(e),
  );

  return {
    ...remoteValue,
    gender: remoteValue?.gender || 'male',
    province: remoteValue?.province?.id || HCMId,
    establishedDate,
    birthDate,
    isCompany: remoteValue?.type === 'Company',
    avatar: remoteValue?.avatar,
    avatarUrl: remoteValue?.avatar?.originalUrl,
    otherCompany: otherCompany?.[0] || '',
  };
};

const formatDate = 'YYYY-MM-DD',
  currentFormat = 'YYYYMMDD';

export const useUpgradePremiumAccount = () => {
  const [step, setStep] = useState(1);
  const form = useForm({
    defaultValues: {
      ...blankState,
    },
    resolver: async (data) => {
      if (data.email) {
        data.email = data?.email?.toString().trim();
      }
      const { error, value: values } = upgradePremiumAccountSchema.validate(
        {
          ...data,
          birthDate: moment(data.birthDate, currentFormat).format(formatDate),
          establishedDate: moment(data.establishedDate, currentFormat).format(
            formatDate,
          ),
          step,
        },
        {
          abortEarly: false,
        },
      );
      return getErrorsFormHook({ error, values });
    },
  });

  const { data, loading } = useQuery(MY_PROFILE_REQUEST, {
    variables: {
      tab: 'developer',
    },
  });

  const { data: dataBasic } = useQuery(GET_USER_BASIC_INFO, {
    variables: {
      userId: data?.myProfileRequest?.developer,
    },
    skip: !data?.myProfileRequest?.developer,
  });

  const { reset } = form;
  const convertData = formatRawData(data?.myProfileRequest);
  useEffect(async () => {
    if (data) {
      reset({
        ...convertData,
        developerName: dataBasic?.getUserBasicInfo.fullName || '',
        frontIdImages: data?.myProfileRequest?.frontIdImages?.map((e) => ({
          fileId: e,
        })),
        backIdImages: data?.myProfileRequest?.backIdImages?.map((e) => ({
          fileId: e,
        })),
        companyLicense: data?.myProfileRequest?.companyLicense?.map((e) => ({
          fileId: e,
        })),
      });
    }
  }, [data, dataBasic]);

  return { form, loading, setStep, step };
};
