import React, { useState } from 'react';
import styles from '@styles/components/InputV2.module.scss';
import ErrorForFormHook from '@components/ErrorForFormHook';

export type InputV2Props = {
  label?: string,
  name?: string,
  inputClassName?: string,
  labelClassName?: string,
  labelContainerClass?: string,
  invalid?: boolean,
  validationText?: string,
  inputOuterClassName?: string,
  isRequire?: boolean,
  renderRightComponent?: Function,
  renderError?: Function,
  errors?: Object,
  disabled?: boolean,
  inputClass?: string,
  errorMessage?: string,
  showMaxLength?: boolean,
  maxLength?: number,
  value?: string,
  onChange?: Function,
  showClose?: boolean,
  onDelete?: Function,
};

export default React.forwardRef(MyInputV2);

function MyInputV2(props: InputV2Props, ref) {
  const {
    label,
    labelClassName,
    inputClassName,
    inputOuterClassName,
    labelContainerClass,
    invalid,
    isRequire,
    renderRightComponent,
    errors,
    name,
    disabled,
    inputClass,
    errorMessage,
    showMaxLength,
    maxLength,
    value,
    onChange = () => {},
    onDelete = () => {},
    showClose = true,
    ...inputProps
  } = props;
  const [focused, setOnFocus] = useState(false);
  return (
    <div className={`w-full ${inputClassName}`}>
      {label && (
        <div className={`flex flex-row space-x-1 ${labelContainerClass}`}>
          <div className={`${styles.label} ${labelClassName}`}>{label}</div>
          {isRequire && <div className="text-red-600 text-13">*</div>}
        </div>
      )}
      <div className="flex flex-col flex-1 w-full">
        <div
          className={`${styles.inputContainer} ${
            invalid && styles.inputError
          } ${inputOuterClassName} ${
            focused && !disabled && styles.inputFocus
          }`}>
          <input
            {...inputProps}
            value={value}
            onWheel={(event) => event.currentTarget.blur()}
            onChange={onChange}
            maxLength={maxLength}
            disabled={disabled}
            ref={ref}
            onFocus={(e) => {
              inputProps.onFocus && inputProps.onFocus(e);
              setOnFocus(true);
            }}
            onBlur={(e) => {
              inputProps.onBlur && inputProps.onBlur(e);
              setOnFocus(false);
            }}
            className={`${styles.input} ${inputClass}`}
          />
          {!!value?.length && showClose && (
            <img
              onClick={() => {
                onChange({ target: { value: '' } });
                onDelete();
              }}
              alt="close"
              src="/svg/closeIcon.svg"
              className="w-26px h-26px cursor-pointer"
            />
          )}
          {renderRightComponent && renderRightComponent()}
        </div>
        <div
          className="flex flex-col relative"
          style={{ minHeight: showMaxLength ? 15 : 0 }}>
          <ErrorForFormHook errors={errors} name={name} />
          {!!errorMessage?.length && (
            <div className="text-14 text-red-400 font-text mt-4px">
              {errorMessage}
            </div>
          )}
          {showMaxLength && (
            <div className="text-11 text-black font-text absolute right-0 bottom-0">
              {maxLength - value?.length}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
