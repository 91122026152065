import { useQuery } from '@apollo/client';
import { READ_MEDIA_URLS } from '@schemas/mutations/image';
import { useDropzone } from 'react-dropzone';
import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import CropAvatar from '@components/ChangeAvatar/CropAvatar';
import { getAvatarUrl } from '@utils/helper';
import UploadErrorModal from '@components/pages/PublishRealEstate/components/UploadErrorModal';

const ModalWrapper = dynamic(() => import('@components/ModalWrapper'), {
  ssr: false,
});

type Props = {
  avatarUrl?: string,
  avatarId?: string,
  wrapperClass?: string,
  setAvatar?: Function,
  type?: string,
  disabled?: boolean,
};

export default function ChangeAvatar(props: Props) {
  const {
      avatarUrl,
      avatarId,
      wrapperClass,
      setAvatar = () => {},
      type,
      disabled,
    } = props,
    [showModal, setShowModal] = useState(false),
    [openModal, setOpenModal] = useState(false);
  const [file, setFile] = useState('');

  const { getInputProps, getRootProps } = useDropzone({
    accept: ['image/*'],
    onDrop: (files) => {
      if (files[0]?.size / 1048576 > 10) {
        setOpenModal(true);
      } else if (files[0]) {
        setFile(files[0]);
        setShowModal(true);
      }
    },
  });
  const { data } = useQuery(READ_MEDIA_URLS, {
    variables: {
      fileId: avatarId,
    },
    skip: avatarUrl || !avatarId,
  });

  const defaultAvatar = getAvatarUrl({ type });

  const avatar = avatarUrl || data?.readMediaUrls.originalUrl;

  const nonAvatar = !avatar?.length;

  const isDisabled = showModal || disabled;

  return (
    <div
      {...getRootProps()}
      className={`lg:w-160px lg:h-160px w-112px h-112px rounded-rd50 bg-cover bg-no-repeat bg-center ${
        isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
      } ${wrapperClass} relative ${nonAvatar && 'border border-gray-400'}`}
      style={{
        backgroundImage: `url("${avatar}")`,
      }}>
      {nonAvatar && (
        <img
          src={defaultAvatar}
          className="w-full h-full rounded-rd50"
          alt="avt"
        />
      )}
      <img
        alt="camrea"
        src="/svg/cameraV2.svg"
        className="w-24px h-24px absolute lg:top-12px top-2 lg:right-12px right-0"
      />
      <input
        {...getInputProps()}
        style={{ display: 'none' }}
        type="file"
        multiple={false}
        disabled={isDisabled}
      />
      {showModal && (
        <ModalWrapper
          className="rounded-rd8px"
          open={showModal}
          onClose={() => setShowModal(false)}>
          <CropAvatar
            close={() => setShowModal(false)}
            configs={{
              previewUrl: file ? URL.createObjectURL(file) : '',
              file,
              onChangeImage: setAvatar,
            }}
          />
        </ModalWrapper>
      )}
      <UploadErrorModal open={openModal} onClose={() => setOpenModal(false)} />
    </div>
  );
}
