import Joi from 'joi';
import {
  // regexValidateIdNumber,
  regexValidateName,
  regexValidationCompanyName,
} from '@utils/configs/RegexValidation';

export const loginSchema = Joi.object({
  username: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = 'Vui lòng nhập email hoặc số điện thoại của bạn';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  password: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = 'Vui lòng nhập mật khẩu của bạn';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
}).unknown();

export const signUpSchema = Joi.object({
  fullName: Joi.string()
    .when('userType', {
      is: 'Company',
      then: Joi.string().allow(''),
      otherwise: Joi.string().regex(regexValidateName).required(),
    })
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = 'Vui lòng nhập họ và tên của bạn';
            break;
          case 'string.pattern.base':
            err.message = 'Họ tên không được chứa số hoặc ký tự đặc biệt';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  companyName: Joi.string()
    .when('userType', {
      is: 'Company',
      then: Joi.string().regex(regexValidationCompanyName).required(),
      otherwise: Joi.string().allow(''),
    })
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = 'Vui lòng nhập tên doanh nghiệp';
            break;
          case 'string.pattern.base':
            err.message = 'Tên doanh nghiệp không được chứa ký tự đặc biệt';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  otherCompany: Joi.string()
    .when('userType', {
      is: 'Company',
      then: Joi.when('isCheckOtherCompany', {
        is: 1,
        then: Joi.string().required(),
        otherwise: Joi.string().allow(''),
      }),
      otherwise: Joi.string().allow(''),
    })
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = 'Vui lòng nhập giá trị';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  taxId: Joi.string()
    .when('userType', {
      is: 'Company',
      then: Joi.string().min(10).max(15).required(),
      otherwise: Joi.string().allow(''),
    })
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = 'Vui lòng nhập mã số thuế';
            break;
          case 'string.min':
            err.message = 'Mã số thuế phải từ 10 đến 15 ký tự!';
            break;
          case 'string.max':
            err.message = 'Mã số thuế phải từ 10 đến 15 ký tự!';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  phone: Joi.string()
    .min(10)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = 'Vui lòng nhập đúng số điện thoại của bạn';
            break;
          case 'string.min':
            err.message = 'Số điện thoại phải đủ 10 ký tự';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  companyFields: Joi.array()
    .when('userType', {
      is: 'Company',
      then: Joi.array().items(Joi.string().required()),
      otherwise: Joi.array().items(Joi.string()).single().optional(),
    })
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'array.includesRequiredUnknowns':
            err.message = 'Vui lòng chọn loại doanh nghiệp';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  password: Joi.string()
    .min(8)
    .max(32)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = 'Vui lòng nhập mật khẩu để bảo mật thông tin';
            break;
          case 'string.min':
            err.message = 'Mật khẩu từ 8-32 ký tự';
            break;
          case 'string.max':
            err.message = 'Mật khẩu từ 8-32 ký tự';
            break;
          case 'string.pattern.base':
            err.message = 'Mật khẩu không hợp lệ';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  phoneVerifyToken: Joi.string()
    .when('phone', {
      is: '',
      then: Joi.string().allow(''),
      otherwise: Joi.string().required(),
    })
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = 'Số điện thoại của bạn chưa được xác thực';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  otp: Joi.string()
    .when('phone', {
      is: '',
      then: Joi.string().allow(''),
      otherwise: Joi.string().required(),
    })
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = 'Vui lòng nhập mã xác thực';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
}).unknown();

export const forgotPasswordSchema = Joi.object({
  authString: Joi.when('step', {
    is: 1,
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'string.empty':
          err.message = 'Vui lòng nhập email hoặc số điện thoại của bạn.';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
  otpCode: Joi.when('step', {
    is: 2,
    then: Joi.string().required().max(6).min(6),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'string.empty':
          err.message = 'Vui lòng nhập mã xác nhận của bạn.';
          break;
        case 'string.min':
          err.message = 'Mã xác nhận phải đủ 6 kí tự';
          break;
        case 'string.max':
          err.message = 'Mã xác nhận phải đủ 6 kí tự';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
  newPassword: Joi.when('step', {
    is: 3,
    then: Joi.string().min(8).max(32).required(),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'string.empty':
          err.message = 'Vui lòng nhập mật khẩu.';
          break;
        case 'string.min':
          err.message = 'Mật khẩu từ 8-32 ký tự';
          break;
        case 'string.max':
          err.message = 'Mật khẩu từ 8-32 ký tự';
          break;
        case 'string.pattern.base':
          err.message = 'Mật khẩu không hợp lệ';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
  confirmPassword: Joi.when('step', {
    is: 3,
    then: Joi.string().when('newPassword', {
      is: '',
      then: Joi.string().min(8).max(32).required(),
      otherwise: Joi.valid(Joi.ref('newPassword')),
    }),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'string.empty':
          err.message = 'Vui lòng nhập mật khẩu mới';
          break;
        case 'any.only':
          err.message =
            'Bạn nhập lại mật khẩu mới không đúng. Vui lòng nhập lại';
          break;
        case 'string.min':
          err.message = 'Mật khẩu từ 8-32 ký tự';
          break;
        case 'string.max':
          err.message = 'Mật khẩu từ 8-32 ký tự';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
}).unknown();

export const changePasswordSchema = Joi.object({
  currentPassword: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = 'Vui lòng nhập lại mật khẩu cũ';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  newPassword: Joi.string()
    .min(8)
    .max(32)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = 'Vui lòng nhập mật khẩu mới';
            break;
          case 'string.min':
            err.message = 'Mật khẩu từ 8-32 ký tự';
            break;
          case 'string.max':
            err.message = 'Mật khẩu từ 8-32 ký tự';
            break;
          case 'string.pattern.base':
            err.message = 'Mật khẩu không hợp lệ';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  confirmPassword: Joi.string()
    .when('newPassword', {
      is: '',
      then: Joi.string().min(8).max(32).required(),
      otherwise: Joi.valid(Joi.ref('newPassword')),
    })
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = 'Vui lòng nhập lại mật khẩu mới';
            break;
          case 'any.only':
            err.message =
              'Bạn nhập lại mật khẩu mới không đúng. Vui lòng nhập lại';
            break;
          case 'string.min':
            err.message = 'Mật khẩu từ 8-32 ký tự';
            break;
          case 'string.max':
            err.message = 'Mật khẩu từ 8-32 ký tự';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
});

const now = Date.now();
// const cutoffDate = new Date(now - 1000 * 60 * 60 * 24 * 365 * 18); // go back by 21 years

export const upgradePremiumAccountSchema = Joi.object({
  // email: Joi.when('step', {
  //   is: 1,
  //   then: Joi.string()
  //     .allow('')
  //     .email({ tlds: { allow: false } }),
  //   // .required(),
  //   otherwise: Joi.any(),
  // }).error((errors) => {
  //   errors.forEach((err) => {
  //     switch (err.code) {
  //       // case 'string.empty':
  //       //   err.message = 'Vui lòng nhập email';
  //       //   break;
  //       // case 'string.base':
  //       //   err.message = 'Vui lòng nhập email';
  //       //   break;
  //       case 'string.email':
  //         err.message = 'Vui lòng nhập đúng định dạng mail';
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  //   return errors;
  // }),
  fullName: Joi.when('step', {
    is: 1,
    then: Joi.string().when('type', {
      is: 'Company',
      then: Joi.string().allow('', null),
      otherwise: Joi.string().regex(regexValidateName).required(),
    }),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'string.empty':
          err.message = 'Vui lòng nhập họ và tên của bạn';
          break;
        case 'string.pattern.base':
          err.message = 'Họ tên không được chứa số hoặc ký tự đặc biệt';
          break;
        default:
          break;
      }
    });
    return errors;
  }),

  companyName: Joi.when('step', {
    is: 1,
    then: Joi.string().when('type', {
      is: 'Company',
      then: Joi.string().regex(regexValidationCompanyName).required(),
      otherwise: Joi.string().allow('', null),
    }),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'string.empty':
          err.message = 'Vui lòng nhập họ và tên của bạn';
          break;
        case 'string.pattern.base':
          err.message = 'Họ tên không được chứa số hoặc ký tự đặc biệt';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
  // idNumber: Joi.when('step', {
  //   is: 1,
  //   then: Joi.when('type', {
  //     is: 'Company',
  //     then: Joi.any(),
  //     otherwise: Joi.string().allow().regex(regexValidateIdNumber),
  //   }),
  //   otherwise: Joi.any(),
  // }).error((errors) => {
  //   errors.forEach((err) => {
  //     switch (err.code) {
  //       case 'string.pattern.base':
  //         err.message = 'Vui lòng nhập 9 hoặc 12 chữ số';
  //         break;
  //       // case 'string.any':
  //       // case 'string.base':
  //       // case 'string.empty':
  //       //   err.message = 'Vui lòng nhập số CMND';
  //       //   break;
  //       default:
  //         break;
  //     }
  //   });
  //   return errors;
  // }),
  province: Joi.when('step', {
    is: 1,
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'string.empty':
          err.message = 'Vui lòng chọn khu vực hoạt động';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
  // birthDate: Joi.when('step', {
  //   is: 1,
  //   then: Joi.when('type', {
  //     is: 'Company',
  //     then: Joi.any(),
  //     otherwise: Joi.date().max(cutoffDate).required(),
  //   }),
  //   otherwise: Joi.any(),
  // }).error((errors) => {
  //   errors.forEach((err) => {
  //     switch (err.code) {
  //       case 'date.base':
  //         err.message = 'Vui lòng chọn ngày sinh';
  //         break;
  //       case 'date.max':
  //         err.message = 'Vui lòng chọn ngày sinh trên 18 tuổi';
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  //   return errors;
  // }),

  establishedDate: Joi.when('step', {
    is: 1,
    then: Joi.when('type', {
      is: 'Company',
      then: Joi.date().max(now).required(),
      otherwise: Joi.any(),
    }),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'date.base':
          err.message = 'Vui lòng chọn ngày thành lập';
          break;
        case 'date.max':
          err.message = 'Ngày thành lập không hợp lệ';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
  // avatar: Joi.when('step', {
  //   is: 2,
  //   then: Joi.string(),
  //   otherwise: Joi.any(),
  // }).error((errors) => {
  //   errors.forEach((err) => {
  //     switch (err.code) {
  //       case 'string.empty':
  //       case 'any.required':
  //       case 'string.base':
  //         err.message = 'Vui lòng chọn avatar';
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  //   return errors;
  // }),
  frontIdImages: Joi.when('step', {
    is: 2,
    then: Joi.when('type', {
      is: 'Company',
      then: Joi.any(),
      otherwise: Joi.array().min(0).max(6),
    }),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        // case 'array.min':
        //   err.message = 'Vui lòng tải lên ảnh mặt trước CMND/ CCCD';
        //   break;
        case 'array.max':
          err.message = 'Số lượng ảnh tối đa là 6';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
  backIdImages: Joi.when('step', {
    is: 2,
    then: Joi.when('type', {
      is: 'Company',
      then: Joi.any(),
      otherwise: Joi.array().min(0).max(6),
    }),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        // case 'array.min':
        //   err.message = 'Vui lòng tải lên ảnh mặt sau CMND/ CCCD';
        //   break;
        case 'array.max':
          err.message = 'Số lượng ảnh tối đa là 6';
          break;
        default:
          break;
      }
    });
    return errors;
  }),

  companyLicense: Joi.when('step', {
    is: 2,
    then: Joi.when('type', {
      is: 'Company',
      then: Joi.array().required().min(1).max(6),
      otherwise: Joi.any(),
    }),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'array.min':
          err.message = 'Vui lòng tải lên giấy phép kinh doanh';
          break;
        case 'array.max':
          err.message = 'Số lượng ảnh tối đa là 6';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
}).unknown();

export const upgradeCompanySchema = Joi.object({
  email: Joi.when('step', {
    is: 1,
    then: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'string.empty':
          err.message = 'Vui lòng nhập email';
          break;
        case 'string.base':
          err.message = 'Vui lòng nhập email';
          break;
        case 'string.email':
          err.message = 'Vui lòng nhập đúng định dạng mail';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
  companyName: Joi.when('step', {
    is: 1,
    then: Joi.string().regex(regexValidationCompanyName).required(),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'string.empty':
          err.message = 'Vui lòng nhập tên công ty';
          break;
        case 'string.pattern.base':
          err.message = 'Tên công ty không được chứa số hoặc ký tự đặc biệt';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
  description: Joi.when('step', {
    is: 1,
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'string.empty':
          err.message = 'Vui lòng nhập giới thiệu';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
  province: Joi.when('step', {
    is: 1,
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'string.empty':
          err.message = 'Vui lòng chọn khu vực hoạt động';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
  establishedDate: Joi.when('step', {
    is: 1,
    then: Joi.date().max(now).required(),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'date.base':
          err.message = 'Vui lòng chọn ngày thành lập';
          break;
        case 'date.max':
          err.message = 'Ngày thành lập không hợp lệ';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
  companyLicense: Joi.when('step', {
    is: 2,
    then: Joi.array().required().min(1).max(6),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'array.min':
          err.message = 'Vui lòng tải lên giấy phép kinh doanh';
          break;
        case 'array.max':
          err.message = 'Số lượng ảnh tối đa là 6';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
  mediaOfCompany: Joi.when('step', {
    is: 2,
    then: Joi.array().required().min(1).max(6),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'array.min':
          err.message = 'Vui lòng tải lên hình ảnh';
          break;
        case 'array.max':
          err.message = 'Số lượng ảnh tối đa là 6';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
  attachments: Joi.when('step', {
    is: 2,
    then: Joi.array().required().min(1).max(6),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'array.min':
          err.message = 'Vui lòng tải lên tài liệu đính kèm';
          break;
        case 'array.max':
          err.message = 'Số lượng tài liệu tối đa là 6';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
  avatar: Joi.when('step', {
    is: 2,
    then: Joi.string(),
    otherwise: Joi.any(),
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        case 'string.empty':
        case 'any.required':
          err.message = 'Vui lòng chọn avatar';
          break;
        default:
          break;
      }
    });
    return errors;
  }),
}).unknown();
