import SelectComponent from '@components/SelectComponent';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_LIST_PROVINCES } from '@schemas/queries/map';
import { convertDataSelections } from '@components/AddressDropdown/CityDropdown';
import DropdownForSearchList from '@components/AdvanceSearchForSearchList/components/DropdownForSearchList';

type Props = {
  selectAll?: boolean,
  onDelete?: Function,
  wrapperContent?: string,
  hideDeleteButton?: boolean,
};

export default function CityButton(props: Props) {
  const {
    selectAll = true,
    onDelete = () => {},
    wrapperContent,
    hideDeleteButton,
  } = props;
  const { control, setValue } = useFormContext();
  const { data, loading } = useQuery(GET_LIST_PROVINCES),
    rawData = selectAll
      ? [{ id: '', name: 'Tất cả' }, ...(data?.getListProvinces || [])]
      : [...(data?.getListProvinces || [])];
  const dataSelections = convertDataSelections(rawData);
  const [disabledWhenDelete, setDisabledWhenDelete] = useState(false);
  return (
    <Controller
      control={control}
      name="province"
      render={({ field: { onChange, value } }) => {
        return (
          <DropdownForSearchList
            wrapperContent={wrapperContent}
            key={Math.random() + value}
            titleMobile="Tỉnh/ Thành phố"
            onDelete={() => {
              onChange('');
              setValue('district', '');
              setValue('ward', '');
              setValue('street', '');
              setDisabledWhenDelete(true);
              setTimeout(() => {
                setDisabledWhenDelete(false);
              }, 100);
              onDelete();
            }}
            value={value}
            setValue={(nextValue) => {
              onChange(nextValue);
              setValue('district', '');
              setValue('ward', '');
              setValue('street', '');
            }}
            disabled={disabledWhenDelete}
            hideDeleteButton={value === '' || hideDeleteButton}
            configs={dataSelections}
            isShowSearchBox
            DropdownComponent={SelectComponent}
            contextProps={{ hasItemRadio: true, loading }}
            customValue={() => {
              return (
                <div
                  className={`flex-1 px-2 text-left text-13 truncate text-blue-main`}>
                  {dataSelections?.find((e) => e.key === value)?.value ||
                    'Chọn giá trị'}
                </div>
              );
            }}
          />
        );
      }}
    />
  );
}
