import { Controller, FormProvider } from 'react-hook-form';
import MyInputV2 from '@components/inputV2';
import { HCMId, maxLength } from '@utils/configs/Common/CommonConfigs';
import UserConfiguarations from '@utils/configs/UserConfiguarations/userConfiguarations.json';
import CompanyField from '@components/CompanyField';
import Label from '@components/label';
import RadioButton from '@components/radioButton';
import CityButton from '@components/AdvanceSearch/components/Address/CityButton';
import React, { useCallback, useState } from 'react';
import SelectBirthDate from '@components/SelectBirthDate';
import MyTextAreaInput from '@components/TextAreaInput';
import MyButton from '@components/Button';
import { entryNumber } from '@utils/helper';
import { useUpgradePremiumAccount } from '@hooks/auth/upgradePremiumAccount/useUpgradePremiumAccount';
import UpLoadImageInfo from '@components/UpdatePremiumAccount/Components/UpLoadImageInfo';
import { useMutation } from '@apollo/client';
import { UPGRADE_PREMIUM_ACCOUNT } from '@schemas/mutations/auth';
import moment from 'moment/moment';
import pickBy from 'lodash/pickBy';
import { useUpgradeAccountContext } from '@components/UpdatePremiumAccount/context';
import PageTitle2 from '@components/pageTitle2';
import styles from '@styles/components/UpdatePremiumAccount/UpdatePremiumAccount.module.scss';
import { regexValidateEmail } from '@utils/configs/RegexValidation';
// import _ from 'lodash';

const formatDate = 'YYYY-MM-DD',
  currentFormat = 'YYYYMMDD';

export default function EnterInfo() {
  const { form, setStep, step } = useUpgradePremiumAccount();
  const [error, setError] = useState('');

  const { setOpenModalSuccess, setOpenModal } = useUpgradeAccountContext();

  const [upgradePremiumAccount] = useMutation(UPGRADE_PREMIUM_ACCOUNT, {
    onCompleted: () => {
      setOpenModal(false);
      setOpenModalSuccess(true);
    },
  });

  const { watch, control, setValue, handleSubmit } = form;

  const userTypeSelections = UserConfiguarations.userType;

  const isCompany = watch('isCompany');

  const type = watch('type');

  const isBroker = type === 'Broker';

  const birthDate = moment(watch('birthDate'), currentFormat).format(
      formatDate,
    ),
    establishedDate = moment(watch('establishedDate'), currentFormat).format(
      formatDate,
    );

  const onNext = useCallback(
    async (data) => {
      if (step === 1) {
        setStep(2);
      }
      if (step === 2) {
        if (data.email) {
          data.email = data?.email?.toString().trim();
        }

        const t = {
          ...data,
          fullName: isCompany ? null : data.fullName,
          companyName: isCompany ? data.companyName : null,
          gender: isCompany ? null : data.gender,
          company: isBroker && data?.company ? data?.company : null,
          birthDate:
            !isCompany && moment(birthDate).isValid() ? birthDate : null,
          establishedDate:
            isCompany && moment(establishedDate).isValid()
              ? establishedDate
              : null,
          frontIdImages: data.frontIdImages.map((item) => item.fileId),
          backIdImages: data.backIdImages.map((item) => item.fileId),
          companyLicense: data.companyLicense.map((item) => item.fileId),
          idNumber: !isCompany ? data.idNumber : null,
        };
        try {
          await upgradePremiumAccount({
            variables: {
              ...pickBy(t, (e) => e),
              email: data.email || '',
              avatar: data.avatar || null,
            },
          });
        } catch (e) {
          alert(e.message);
        }
      }
    },
    [isCompany, step],
  );

  return (
    <div className="flex-1 flex flex-col overflow-y-auto relative">
      <PageTitle2
        onRowClick={() => {
          if (step === 2) {
            setStep(1);
          } else {
            setOpenModal(false);
          }
        }}
        title="Hoàn thành hồ sơ"
        wrapperClass="lg:hidden"
      />
      <div
        className="hidden lg:flex bg-white sticky top-0 border-b border-blue-main z-10"
        style={{ padding: '10px 19px' }}>
        <p className="uppercase text-16 font-textBold font-bold text-blue-main">
          hoàn thành hồ sơ cá nhân
        </p>
      </div>
      <FormProvider {...form}>
        {step === 1 && (
          <div
            className="flex flex-1 flex-col"
            style={{ padding: '12px 12px 24px' }}>
            <p className="heading4">Thông tin nhà phát triển</p>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <MyInputV2
                  inputClass="h-40px"
                  name="developerName"
                  inputOuterClassName="flex-1"
                  inputClassName="flex flex-col lg:flex-row lg:items-center mt-16px"
                  label="Nhà phát triển"
                  placeholder="Tên nhà phát triển"
                  labelContainerClass="w-134px"
                  isRequire
                  value={value}
                  disabled
                  showClose={false}
                />
              )}
              name="developerName"
            />
            <div className="w-full h-1 border-t border-gray-300 my-16px" />
            <p className="heading4">Kiểm tra lại thông tin của bạn</p>
            <div className="flex w-full items-center mt-8px">
              <img
                className="w-24px h-24px mr-4px"
                src="/svg/ico_info.svg"
                alt="info"
              />
              <p className="text-13 font-text text-blue-main">
                Vui lòng kiểm tra lại thông tin của bạn một lần nữa trước khi
                nâng cấp
              </p>
            </div>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <MyInputV2
                  inputClass="h-40px"
                  name="type"
                  inputOuterClassName="flex-1"
                  inputClassName="flex flex-col lg:flex-row lg:items-center mt-16px"
                  label="Vai trò"
                  placeholder="Vai trò"
                  labelContainerClass="w-134px"
                  isRequire
                  value={userTypeSelections.find((e) => e.key === value)?.value}
                  disabled
                  showClose={false}
                />
              )}
              name="type"
            />
            {isCompany && (
              <>
                <Controller
                  rules={{
                    required: isCompany ? 'Vui lòng nhập mã số thuế' : '',
                  }}
                  control={control}
                  render={({ field: { value } }) => (
                    <MyInputV2
                      inputClass="h-40px"
                      name="taxId"
                      inputOuterClassName="flex-1"
                      inputClassName="flex flex-col lg:flex-row lg:items-center mt-16px"
                      label="Mã số thuế"
                      placeholder="Nhập giá trị"
                      labelContainerClass="w-134px"
                      isRequire
                      value={value}
                      disabled
                      showClose={false}
                    />
                  )}
                  name="taxId"
                />
                <Controller
                  control={control}
                  render={({
                    field,
                    field: { value, onChange },
                    formState: { errors },
                    fieldState: { invalid },
                  }) => (
                    <MyInputV2
                      {...field}
                      invalid={invalid}
                      inputClass="h-40px"
                      name="companyName"
                      errors={errors}
                      inputOuterClassName="flex-1"
                      inputClassName="flex flex-col lg:flex-row lg:items-center mt-16px"
                      label="Tên doanh nghiệp"
                      placeholder="Nhập giá trị"
                      labelContainerClass="w-134px"
                      isRequire
                      value={value}
                      onChange={(e) =>
                        onChange(e.target.value.substr(0, maxLength.name))
                      }
                      showMaxLength
                      maxLength={maxLength.name}
                      autoComplete="new-password"
                    />
                  )}
                  name="companyName"
                />
              </>
            )}
            {!isCompany && (
              <Controller
                control={control}
                render={({
                  field,
                  field: { value, onChange },
                  formState: { errors },
                  fieldState: { invalid },
                }) => (
                  <MyInputV2
                    {...field}
                    invalid={invalid}
                    errors={errors}
                    inputClass="h-40px"
                    name="fullName"
                    inputOuterClassName="flex-1"
                    inputClassName="flex flex-col lg:flex-row lg:items-center mt-16px"
                    label="Họ và tên"
                    placeholder="Nhập giá trị"
                    labelContainerClass="w-134px"
                    isRequire
                    value={value}
                    onChange={(e) =>
                      onChange(e.target.value.substr(0, maxLength.name))
                    }
                    showMaxLength
                    maxLength={maxLength.name}
                    autoComplete="new-password"
                  />
                )}
                name="fullName"
              />
            )}
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <MyInputV2
                  inputClass="h-40px"
                  name="phones"
                  inputOuterClassName="flex-1"
                  inputClassName="flex flex-col lg:flex-row lg:items-center mt-16px"
                  label="Số điện thoại"
                  placeholder="Nhập giá trị"
                  labelContainerClass="w-134px"
                  isRequire
                  value={value?.[0]}
                  disabled
                  showClose={false}
                />
              )}
              name="phones"
            />
            {!isCompany && (
              <Controller
                control={control}
                render={({ field: { value, onChange } }) => {
                  const isFeMale = value === 'female';
                  return (
                    <div className="w-full flex mt-16px items-center">
                      <Label label="Giới tính" wrapperClass="w-133px" />
                      <RadioButton
                        title="Nam"
                        isChecked={!isFeMale}
                        wrapperClass="mr-24px"
                        onPress={() => onChange('male')}
                      />
                      <RadioButton
                        title="Nữ"
                        isChecked={isFeMale}
                        onPress={() => onChange('female')}
                      />
                    </div>
                  );
                }}
                name="gender"
              />
            )}
            {!isCompany && (
              <Controller
                control={control}
                render={({
                  field,
                  field: { value, onChange },
                  formState: { errors },
                  fieldState: { invalid },
                }) => (
                  <MyInputV2
                    {...field}
                    invalid={invalid}
                    errors={errors}
                    inputClass="h-40px"
                    name="idNumber"
                    inputOuterClassName="flex-1"
                    inputClassName="flex flex-col lg:flex-row lg:items-center mt-16px"
                    label="Số CMND/ CCCD"
                    placeholder="Nhập giá trị"
                    labelContainerClass="w-134px"
                    // isRequire
                    value={value || ''}
                    onChange={(e) =>
                      entryNumber(e.target.value, onChange, maxLength.idNumber)
                    }
                    autoComplete="new-password"
                  />
                )}
                name="idNumber"
              />
            )}
            <Controller
              control={control}
              render={({
                field,
                field: { value, onChange },
                formState: { errors },
                fieldState: { invalid },
              }) => (
                <MyInputV2
                  errorMessage={error}
                  {...field}
                  invalid={invalid}
                  errors={errors}
                  inputClass="h-40px"
                  name="email"
                  inputOuterClassName="flex-1"
                  inputClassName="flex flex-col lg:flex-row lg:items-center mt-16px"
                  label="Email"
                  placeholder="Nhập giá trị"
                  labelContainerClass="w-134px"
                  // isRequire
                  value={value || ''}
                  onChange={(e) =>
                    onChange(e.target.value.substr(0, maxLength.email))
                  }
                  onInput={(e) => {
                    const value = e.target.value;
                    value?.length
                      ? !regexValidateEmail.test(value)
                        ? setError('Vui lòng nhập email đúng định dạng')
                        : setError('')
                      : setError('');
                  }}
                />
              )}
              name="email"
            />

            {isCompany && (
              <Controller
                control={control}
                render={({ field: { value } }) => (
                  <div className="flex flex-col lg:flex-row mt-16px">
                    <Label
                      label="Lĩnh vực kinh doanh"
                      isRequire
                      wrapperClass="w-133px"
                    />
                    <CompanyField value={value} onChange={() => {}} disabled />
                  </div>
                )}
                name="companyFields"
              />
            )}
            {isBroker && (
              <Controller
                control={control}
                render={({
                  field: { value, onChange },
                  formState: { errors },
                }) => (
                  <MyInputV2
                    errors={errors}
                    inputClass="h-40px"
                    name="company"
                    inputOuterClassName="flex-1"
                    inputClassName="flex flex-col lg:flex-row lg:items-center mt-16px"
                    label="Thuộc công ty"
                    placeholder="Nhập giá trị"
                    labelContainerClass="w-134px"
                    value={value || ''}
                    onChange={(e) => onChange(e.target.value.substr(0, 200))}
                  />
                )}
                name="company"
              />
            )}
            {!isCompany && (
              <div className="w-full flex mt-16px flex-col lg:flex-row lg:items-center">
                <div style={{ minWidth: 133 }}>
                  <Label label="Ngày sinh" isRequire />
                </div>
                <SelectBirthDate name="birthDate" />
              </div>
            )}
            {isCompany && (
              <div className="w-full flex mt-16px flex-col lg:flex-row lg:items-center">
                <div style={{ minWidth: 133 }}>
                  <Label label="Ngày thành lập" isRequire />
                </div>
                <SelectBirthDate name="establishedDate" />
              </div>
            )}
            {!isCompany && (
              <div className="w-full flex mt-16px flex-col lg:flex-row lg:items-center">
                <div style={{ minWidth: 133 }}>
                  <Label label="Nơi hoạt động" isRequire />
                </div>
                <CityButton
                  onDelete={() => setValue('province', HCMId)}
                  selectAll={false}
                  wrapperContent={styles.cityButton}
                  hideDeleteButton
                />
              </div>
            )}
            {isCompany && (
              <>
                <div className="w-full h-1 border-t border-gray-300 my-16px" />
                <Controller
                  control={control}
                  render={({
                    formState: { errors },
                    fieldState: { invalid },
                    field: { value, onChange },
                  }) => {
                    return (
                      <div className="flex flex-col mt-16px">
                        <div className="text-blue-main font-semibold text-13 w-190px mb-4px">
                          Giới thiệu về doanh nghiệp
                        </div>
                        <MyTextAreaInput
                          onDelete={() => onChange('')}
                          name="description"
                          errors={errors}
                          invalid={invalid}
                          rows={5}
                          value={value || ''}
                          onChange={(e) =>
                            onChange(e.target.value.substr(0, 3168))
                          }
                          maxlength={3168}
                          placeholder="Nhập giới thiệu về doanh nghiệp"
                        />
                      </div>
                    );
                  }}
                  name="description"
                />
              </>
            )}
          </div>
        )}
        {step === 2 && <UpLoadImageInfo />}
      </FormProvider>
      <div className="flex w-full justify-end sticky bottom-0 bg-white border-t border-gray-300 p-8px">
        <MyButton
          label="Trở về"
          type="secondary"
          wrapperClass="w-120px mr-8px lg:flex hidden"
          onPress={() => {
            if (step === 2) {
              setStep(1);
            }
            if (step === 1) {
              setOpenModal(false);
            }
          }}
        />
        <MyButton
          label={step === 1 ? 'Tiếp tục' : 'Hoàn thành'}
          wrapperClass="lg:w-120px w-full"
          onPress={handleSubmit(onNext)}
        />
      </div>
    </div>
  );
}
