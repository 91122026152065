import { useContext } from 'react';
import { AppContext } from '@utils/context/appContext';
import { useQuery } from '@apollo/client';
import { GET_USER_INFO_BY_FIELD } from '@schemas/queries/users';

const queryFields = ['fullName', 'email', 'phones', 'memberId'];
export const useGetUserInfo = () => {
  const { isLogin } = useContext(AppContext);
  const { data: userInfo, loading } = useQuery(
    GET_USER_INFO_BY_FIELD(queryFields),
    {
      skip: !isLogin,
    },
  );
  return { userInfo, loading };
};
