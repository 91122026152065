import styles from '@styles/components/InputV2.module.scss';
import React from 'react';

type Props = {
  label?: string,
  isRequire?: boolean,
  wrapperClass?: string,
  labelClass?: string,
  style?: Object,
};
export default function Label({
  label,
  isRequire,
  wrapperClass,
  labelClass,
  style,
}: Props) {
  return (
    <div className={`flex flex-row space-x-1 ${wrapperClass}`} style={style}>
      <div className={`${styles.label} ${labelClass}`}>{label}</div>
      {isRequire && (
        <div className="text-red-600 text-13 font-textBold font-bold">*</div>
      )}
    </div>
  );
}
