export const regexValidateEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const regexValidateName =
  /^[a-zA-Z_àáãạảăắằẳẵặâấầẩẫậèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ\s]+$/;
export const regexValidationCompanyName =
  /^[a-zA-Z0-9_àáãạảăắằẳẵặâấầẩẫậèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ\s]+$/;
export const regexValidationPassword =
  /^(?=.*[a-zA-Z])([a-zA-Z0-9!#$%'()+,.\-/:?@[\\\]^_`{}~]+)$/;
export const regexValidateIdNumber = /^(\d{9}|\d{12})$/;
export const regexValidateTaxId = /^(\d{10}|\d{13})$/;
export const regexValidatePhoneNumber = /^0([35789])[0-9]{8}$/;
export const regexValidateFacebook =
  /(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)$/;
export const regexValidatePostTitle = /^(?=.*[a-z])(?=.*[A-Z]).*$/;
export const regexHouseNumber = /^([A-Z0-9]+\/{0,1}){0,10}[A-Z0-9]+$/;
export const regexCoordinate =
  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
